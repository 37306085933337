import React, { useEffect, useState } from "react";
import "../../../assets/main.css";
import "../../../assets/member.css";
import { certificateUrl, requestOpen } from "network/Request";

const FindPassword = () => {
  const [certJSON, setCertJSON] = useState();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const receive = (e) => {
    if (e.data["kmc_cert"]) {
      setCertJSON({
        kmc_cert: e.data["kmc_cert"],
        kmc_cert_num: e.data["cert_num"],
      });
    }
  };

  useEffect(() => {
    window.addEventListener("message", receive, false);
    return () => {
      window.removeEventListener("message", receive);
    };
  }, []);

  const digestMessage = async (message) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return await window.crypto.subtle.digest("SHA-256", data);
  };

  const hexString = (buffer) => {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      const paddedHexCode = hexCode.padStart(2, "0");
      return paddedHexCode;
    });

    return hexCodes.join("");
  };

  const submit = async () => {
    if (!password || password.length <= 0) {
      alert("비밀번호 필수입니다.");
      return;
    }
    if (password !== passwordConfirm) {
      alert("비밀번호 확인 문자가 다릅니다.");
      return;
    }

    const digestValue = await digestMessage(`drenglish${password}`); // Hashing;
    const hashed = hexString(digestValue);

    requestOpen({ "bk-working": "change-password-di", password: hashed, ...certJSON, login_id: loginId}, (result) => {
      alert(result ? "변경되었습니다": "오류");
    });
  };

  return (
    <div>
      <div className="container">
        <div className="login_box">
          <div className="member-top">
            <h1>
              Hello!<br></br>Please Login!
            </h1>
            <span>비밀번호 변경</span>
          </div>
          {!certJSON && (
            <div className="identification">
              <button
                className="id-btn"
                onClick={() => {
                  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                width=0,height=0,left=-1000,top=-1000`;
                  window.open(certificateUrl, "본인인증", params);
                }}
              >
                본인인증
              </button>
              <p>본인 인증 후 비밀번호를 변경하실 수 있습니다.</p>
            </div>
          )}
          {certJSON && (
            <div>
              <div className="login-form">
                <div className="form-group">
                  <label htmlFor="inp_id">아이디</label>
                  <input type="text" id="inp_id" required="" placeholder="ID" value={loginId} onChange={(e) => setLoginId(e.target.value)} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inp_pw">
                  비밀번호(8~16자) <span className="colorRed">*</span>
                </label>
                <input
                  type="password"
                  id="inp_pw"
                  required=""
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inp_pwc">
                  비밀번호 확인 <span className="colorRed">*</span>
                </label>
                <input
                  type="password"
                  id="inp_pwc"
                  required=""
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </div>
              <button className="submit-btn" onClick={() => submit()}>
                변경
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
