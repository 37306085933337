import React, { useContext } from "react";
import { useState } from "react";
import { LoginContext } from "controller/provider/LoginProvider";
import UserInfo from "./mypage/UserInfo";
import UserPay from "./mypage/UserPay";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Mypage = () => {
  const loginContext = useContext(LoginContext);
  const [activeTab, setActiveTab] = useState("monthly");

  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1);
  }

  return (
    <div className="mypage">
      <div className="container">
        <div className="con-tit">
          <h1>Hello! {loginContext.user.userName}</h1>
          <Button onClick={handleGoBack} className="back-btn">
            뒤로가기
          </Button>
        </div>

        <div className="tab-container">
          <input
            type="radio"
            id="tab1"
            name="tabs"
            className="tab"
            checked={activeTab === "monthly"}
            onChange={() => setActiveTab("monthly")}
          />
          <input
            type="radio"
            id="tab2"
            name="tabs"
            className="tab"
            checked={activeTab === "weekly"}
            onChange={() => setActiveTab("weekly")}
          />

          <label
            htmlFor="tab1"
            className={`tab-label ${activeTab === "monthly" ? "active" : ""}`}
          >
            개인정보 수정
          </label>
          <label
            htmlFor="tab2"
            className={`tab-label ${activeTab === "weekly" ? "active" : ""}`}
          >
            결제 정보
          </label>

          <div
            className={`monthly-box ${activeTab === "monthly" ? "active" : ""}`}
          >
            {/* <h1>개인정보 수정</h1> */}
            <UserInfo />
          </div>

          <div
            className={`weekly-box ${activeTab === "weekly" ? "active" : ""}`}
          >
            {/* <h1>결제 현황</h1> */}
            <UserPay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mypage;
