import React, { useEffect, useState } from "react";
import MainGnb from "../gnb/MainGnb";
import WhiteLogo from "../Logo/WhiteLogo";
import BlueLogo from "../Logo/BlueLogo";
import "../../assets/main.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const MainHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const blackTheme =
    location.pathname !== "/" &&
    location.pathname !== "/class-info" &&
    location.pathname !== "/teacher-info";

  const userHeader =
    location.pathname !== "/" &&
    location.pathname !== "/class-info" &&
    location.pathname !== "/teacher-info";

  const [isSticky, setIsSticky] = useState(false);
  const [lastPath, setLastPath] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === lastPath) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      setLastPath(location.pathname);
    }
  }, [location]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  return (
    <div
      className={`header ${isSticky ? "sticky" : ""} ${
        userHeader ? "user-header" : "main_header"
      }`}
    >
      <div className="logo">
        <Link to="/" onClick={() => navigate("/")}>
          {blackTheme || isSticky ? <BlueLogo /> : <WhiteLogo />}
        </Link>
      </div>
      <MainGnb
        isSticky={isSticky}
        blackTheme={blackTheme}
        pathname={location.pathname}
      />
    </div>
  );
};

export default MainHeader;
