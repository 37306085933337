import { request } from "network/Request";

export const listingCredits = (timeMillisec, onlyUsable = true) => {
  return request({
    "bk-working": "listing-user-credit",
    current_millisec: timeMillisec,
    only_usable: onlyUsable,
  });
};

export const listingCreditsMonthly = (startMillisec, endMillisec) => {
  return request({
    "bk-working": "listing-user-credit-monthly",
    start_time: startMillisec,
    end_time: endMillisec,
  });
};
