import React, { forwardRef, useState } from "react";

import { Button, Grid, TextField } from "@mui/material";

import "../assets/main.css";
import "../assets/sub.css";
import "../assets/member.css";
import { requestOpen } from "network/Request";

const FormMail = forwardRef(({onClose}) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [duty, setDuty] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");
  const [sending, setSending] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };
  const handleDutyChange = (event) => {
    setDuty(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    requestOpen(
      {
        "bk-working": "send-mail",
        title: "B2B 문의",
        body: `company:${company}\nname:${name}\nduty:${duty}\nemail:${email}\nphone:${phone}\nguestion:${question}`,
      },
      (result) => {
        setSending(false);
        alert(result ? "전송되었습니다." : "전송실패");

        if (result && onClose) {
          onClose();
        }
      }
    );
  };

  return (
    <div className="form-mail">
      <div className="commu-container">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3 className="form-mail-tit">
                B2B 문의하기
                <span>B2B 제휴 또는 교육에 대한 궁금한 점을 문의해보세요.</span>
              </h3>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                required
                id="name"
                label="이름"
                value={name}
                onChange={handleNameChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                id="phone"
                label="전화번호"
                value={phone}
                onChange={handlePhoneChange}
                fullWidth
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                
                id="company"
                label="회사명"
                value={company}
                onChange={handleCompanyChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                
                id="duty"
                label="담당부서"
                value={duty}
                onChange={handleDutyChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                label="이메일"
                type="email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="question"
                label="문의 내용"
                multiline
                rows={4}
                value={question}
                onChange={handleQuestionChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            disabled={sending}
            className="submit-btn"
            variant="contained"
            color="primary"
            type="submit"
          >
            전송하기
          </Button>
        </form>
      </div>
    </div>
  );
});

export default FormMail;
