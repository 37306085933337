import React, { useState } from "react";
import "../../assets/main.css";
import "../../assets/sub.css";
import quickIcon1 from "../../assets/images/icon_quick1.png";
import quickIcon2 from "../../assets/images/icon_quick2.png";
import quickIcon2_1 from "../../assets/images/icon_quick2_1.png";
import quickIcon2_2 from "../../assets/images/icon_quick2_2.png";
import quickIcon3 from "../../assets/images/icon_quick3.png";
import quickIcon4 from "../../assets/images/icon_quick4.png";
import quickIcon4_1 from "../../assets/images/icon_quick4_1.png";
import quickIcon4_2 from "../../assets/images/icon_quick4_2.png";
import quickIcon4_3 from "../../assets/images/icon_quick4_3.png";
import LevelTable from "components/LevelTable";
import { Modal } from "@mui/material";
import FormMail from "components/FormMail";
import ContactMail from "./ContactMail";

const SideNavBar = () => {
  const [tableopen, setTableopen] = useState(false);
  const [btobMailOpen, setBtoBMailopen] = useState(false);
  const [qnaOpen, setQnaOpen] = useState(false);

  const handleTableopen = () => {
    setTableopen(true);
  };

  const handleTableClose = () => {
    setTableopen(false);
  };

  const handleMailopen = () => {
    setBtoBMailopen(true);
  };
  const handleMailClose = () => {
    setBtoBMailopen(false);
  };
  const handleQnaOpen = () => {
    setQnaOpen(true);
  };
  const handleQnaClose = () => {
    setQnaOpen(false);
  };

  const downloadManual = () => {
    const url =
      "https://drenglish-resource.s3.ap-northeast-2.amazonaws.com/%EB%8B%A5%ED%84%B0%EC%9E%89%EA%B8%80%EB%A6%AC%EC%8B%9C_%EC%82%AC%EC%9A%A9%EC%9E%90%ED%8E%98%EC%9D%B4%EC%A7%80_%EC%82%AC%EC%9A%A9%EC%84%A4%EB%AA%85%EC%84%9C_%EC%9D%BC%EB%B0%98_%EB%B0%B0%ED%8F%AC.pdf";
    window.open(url, "_blank");
  };

  return (
    <div className="quick_menu_wrap">
      <label htmlFor="quick_chk">
        <input id="quick_chk" type="checkbox" />
        <div id="snb">
          <nav>
            <ul>
              <li onClick={handleTableopen}>
                <a>
                  <img src={quickIcon1} alt="레벨표" />
                  레벨표
                </a>
              </li>
              <li>
                <a>
                  <img src={quickIcon2} alt="B2B" />
                  B2B
                </a>
                <ul>
                  <li onClick={handleMailopen}>
                    <a>
                      <img src={quickIcon2_1} alt="B2B-메일" />
                      메일
                    </a>
                  </li>
                  <li>
                    <a href="tel://1811-6047">
                      <img src={quickIcon2_2} alt="B2B-전화" />
                      전화
                    </a>
                  </li>
                </ul>
              </li>
              <li onClick={downloadManual}>
                <a>
                  <img src={quickIcon3} alt="이용메뉴얼" />
                  이용메뉴얼
                </a>
              </li>
              <li>
                <a>
                  <img src={quickIcon4} alt="CONTACT" />
                  CONTACT
                </a>
                <ul>
                  <li onClick={handleQnaOpen}>
                    <a>
                      <img src={quickIcon4_1} alt="메일" />
                      메일
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        window.open("https://pf.kakao.com/_LxhZPxb/chat")
                      }
                    >
                      <img src={quickIcon4_2} alt="카카오톡채널" />
                      카카오톡
                    </a>
                  </li>
                  <li>
                    <a href="tel://1811-6047">
                      <img src={quickIcon4_3} alt="전화" />
                      전화
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <p className="quick_btn"></p>
      </label>
      <Modal open={tableopen} onClose={handleTableClose}>
        <div className="modal">
          <button className="close-btn" onClick={handleTableClose}>
            닫기
          </button>
          <LevelTable />
        </div>
      </Modal>
      <Modal open={btobMailOpen} onClose={handleMailClose}>
        <div className="modal">
          <button className="close-btn" onClick={handleMailClose}>
            닫기
          </button>
          <FormMail onClose={handleMailClose} />
        </div>
      </Modal>
      <Modal open={qnaOpen} onClose={handleQnaClose}>
        <div className="modal">
          <button className="close-btn" onClick={handleQnaClose}>
            닫기
          </button>
          <div className="form-mail">
            <ContactMail onClose={handleQnaClose} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SideNavBar;
