import React, { forwardRef } from "react";

const LevelTable = forwardRef((props, ref) => {
  return (
    <div>
      <div className="pop-cefr">
        <h1>CEFR 아이엘츠/토플/토익/오픽 환산표</h1>
        <div className="cefr-table">
          <table>
            <colgroup></colgroup>
            <thead>
              <tr>
                <th rowSpan="2">Common European Framework (Level)</th>
                <th rowSpan="2">CEFR Can-Do Statement</th>
                <th rowSpan="2">IELTS</th>
                <th>TOEFL</th>
                <th colSpan="2">TOEIC</th>
                <th rowSpan="2">OPIc</th>
              </tr>
              <tr>
                <th>iBT</th>
                <th>TOEIC</th>
                <th>TOEIC Speaking</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan="3">C2</td>
                <td rowSpan="3">
                  <b>능숙한 사용자 (Fluent User)</b>듣고 읽은 모든 내용을 이해할
                  수 있다. 매우 능숙하고 유창하게 발휘할 수 있으며 복잡한
                  상황에서도 세밀한 뉘앙스의 차이까지 구별해낼 수 있다.
                </td>
                <td>9</td>
                <td rowSpan="3" colSpan="4">
                  Cannot Measure at C2 Level
                </td>
              </tr>
              <tr>
                <td>8.5</td>
              </tr>
              <tr>
                <td>8</td>
              </tr>

              <tr>
                <td rowSpan="3">C1</td>
                <td rowSpan="3">
                  <b>능숙한 사용자 (Proficient User)</b>다양한 지문을 이해할 수
                  있으며 함축적 의미도 알아챌 수 있다. 적절한 표현을 찾느라
                  지체함이 없이, 즉각적으로 유창하고 능숙하게 말할 수 있다.
                  복잡한 주제에 대해서도 명확하고 자세한 논점을 담은 글을 써낼
                  수 있다.
                </td>
                <td>7.5</td>
                <td rowSpan="3">110-120</td>
                <td rowSpan="3">945-990</td>
                <td rowSpan="3">200+</td>
                <td rowSpan="3"></td>
              </tr>
              <tr>
                <td>7</td>
              </tr>
              <tr>
                <td>6.5</td>
              </tr>

              <tr>
                <td rowSpan="3">B2</td>
                <td rowSpan="3">
                  <b>중급 사용자 2 (Independent User) </b>큰 긴장감 없이
                  원어민과 유창하게 대화할 수 있으며 자주 쓰이는 표현들을
                  사용한다. 자신이 원하는 표현을 어렵지 않게 사용할 수 있으나
                  가끔 오류를 범할 수 있다.
                </td>
                <td>6</td>
                <td rowSpan="3">87-109</td>
                <td>892-944</td>
                <td>180-199</td>
                <td>Advanced Low</td>
              </tr>
              <tr>
                <td>5.5</td>
                <td className="txt-center">839-891</td>
                <td>160-179</td>
                <td>Intermediate High</td>
              </tr>
              <tr>
                <td>5</td>
                <td className="txt-center">785-838</td>
                <td>140-159</td>
                <td>Intermediate Mid</td>
              </tr>

              <tr>
                <td rowSpan="2">B1</td>
                <td rowSpan="2">
                  <b>중급 사용자 1 (Intermediate User)</b>직장, 학교, 여가
                  장소에서 자주 접하는 정보에 대한 중점 파악이 가능하다. 해당
                  언어를 사용하는 국가를 여행할 때에 일어날 수 있는 대부분의
                  상황을 스스로 해결할 수 있다. 자신이 원하는 것은 표현할 수
                  있지만 구조적 실수를 범하고는 한다.
                </td>
                <td>4.5</td>
                <td rowSpan="2">57-86</td>
                <td>550-784</td>
                <td>120-139</td>
                <td>Intermediate Low</td>
              </tr>
              <tr>
                <td>4</td>
                <td className="txt-center">221-549</td>
                <td>No data available</td>
                <td>
                  Novice
                  <br />
                  High
                </td>
              </tr>

              <tr>
                <td>A2</td>
                <td>
                  <b>초보 사용자 2 (Elementary User)</b>자신과 친숙한 주제를
                  다루는 문장과 자주 쓰이는 쉬운 표현만을 이해할 수 있다. 적절한
                  표현을 찾아내기까지 시간이 걸리는 경우가 많다. 자신의 배경이나
                  자신과 관련된 환경과 물건에 대해서 쉬운 용어들을 사용하여
                  설명할 수 있다.
                </td>
                <td>3</td>
                <td>40-56</td>
                <td>120-220</td>
                <td>No data available</td>
                <td>
                  Novice
                  <br />
                  Mid
                </td>
              </tr>

              <tr>
                <td>A1</td>
                <td>
                  <b>초보 사용자 1 (Basic User)</b>구체적이고 친숙한 상황에
                  대해서만 아주 쉬운 표현을 사용하여 제한적인 의사소통이
                  가능하다. 완벽한 문장을 구사하기 보다는 단어를 조합하는 경우가
                  많다. 느린 속도로 정확하게 말해주고, 도와줄 준비가
                  되어있어야만 가능하다.
                </td>
                <td>2</td>
                <td>No data available</td>
                <td>No data available</td>
                <td>No data available</td>
                <td>
                  Novice
                  <br />
                  Low
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default LevelTable;
