import React, { useState } from "react";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { requestOpen } from "network/Request";

const ContactMail = ({onClose}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");
  const [sending, setSending] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    requestOpen(
      {
        "bk-working": "send-mail",
        title: "사용자 ContactMail 작성",
        body: `name:${name}\nemail:${email}\nphone:${phone}\nguestion:${question}`,
      },
      (result) => {
        setSending(false);

        if (result && onClose) {
          onClose();
        }
        alert(result ? "전송되었습니다." : "전송실패");
      }
    );
    setName("");
    setEmail("");
    setPhone("");
    setQuestion("");
  };

  return (
    <div className="commu-container">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              className="commu-tit"
            >
              닥터잉글리쉬에 대한 궁금한 점을 문의해보세요.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="name"
              label="이름"
              value={name}
              onChange={handleNameChange}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="phone"
              label="전화번호"
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="email"
              label="이메일"
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="question"
              label="문의 내용"
              multiline
              rows={4}
              value={question}
              onChange={handleQuestionChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          disabled={sending}
          className="submit-btn"
          variant="contained"
          color="primary"
          type="submit"
        >
          전송하기
        </Button>
      </form>
    </div>
  );
};

export default ContactMail;
