import React, { forwardRef } from "react";

const PrivacyPolicy = forwardRef((props, ref) => {
  return (
    <div>
        <div className="personal-info">
            <h1>개인정보처리방침</h1>
            <div className="pp-wrap">

                <div className="pri-box">
                    <b>개인정보보호</b>
                    <ol className="num-txt">
                        <li>"닥터잉글리쉬"는 회원의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항는 선택사항으로 합니다.
                            <span>1. 성명</span>
                            <span>2. 전화번호</span>
                            <span>3. 희망ID</span>
                            <span>4. 비밀번호</span>
                            <span>5. 전자우편주소</span>
                        </li>
                        <li>제공된 개인정보는 당해 회원의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임는 닥터잉글리쉬가 집니다.
                            다만, 다음의 경우에는 예외로 합니다.
                            <span>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</span>
                            <span>서비스 이용에 따른 대금정산을 위하여 필요한 경우</span>
                            <span>도용방지를 위하여 본인확인에 필요한 경우</span>
                            <span>법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</span>
                        </li>
                        <li>"닥터잉글리쉬"가 2항과 제3항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처),
                             정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진등에관한법률 
                             제22조제2항이 규정한 사항을 미리 명시하거나 고지 해야 하며 회원는 언제든지 이 동의를 철회할 수 있습니다.</li>
                        <li>회원는 언제든지 "닥터잉글리쉬"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "닥터잉글리쉬"가 이에 대해 지체 없이 
                            필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는 "닥터잉글리쉬"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
                        <li>"닥터잉글리쉬"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행 계좌 등을 포함한 회원의 개인정보의 분실, 도난, 
                            유출, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다.</li>
                        {/* <li>"닥터잉글리쉬" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li> */}
                        <li>"닥터잉글리쉬" 또는 그로부터 개인정보를 제공받는 제3자는 개인정보의 수집목적 또는 제공받는 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
                    </ol>
                </div>


            </div>


        </div>
    </div>
  );
});

export default PrivacyPolicy;
