// let domain = "http://localhost:8080";
let domain = "https://api.dr-english.com";
let normalAPI = domain + "/user-apigate-web";
let openAPI = domain + "/user-apigate-web-open";
let authAPI = domain + "/user-apigate-auth";
let logoutAPI = domain + "/user-apigate-logout";
let fileAPI = domain + "/user-apigate-resource";
export let certificateUrl = domain + "/certificate-start";

export async function request(json, callback) {
  return await requestBase(normalAPI, json, callback);
}

export async function requestOpen(json, callback) {
  return await requestBase(openAPI, json, callback);
}

export async function requestLogout(json, callback) {
  return await requestBase(logoutAPI, json, callback);
}

export async function requestToken(json, callback) {
  return await requestBase(authAPI, json, callback);
}

export function requestFileAPI(json, fileArray, callback) {
  requestFileBase(fileAPI, json, fileArray, callback);
}

export function requestFileBase(api, json, fileArray, callback) {
  var formData = new FormData();
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        callback(json["bk-result"], json);
      } else {
        alert("등록 실패:" + xhr.statusText);
      }
    }
  };

  for (var j in fileArray) {
    formData.append("resources", fileArray[j]);
  }

  formData.append("json", JSON.stringify(json));
  xhr.withCredentials = true;
  xhr.open("POST", api);
  xhr.send(formData);
}

async function requestBase(api, json, callback) {
  let otherParam = {
    crossDomain: true,
    method: "POST", //TODO DRY!!
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "cache-control": "no-cache", //TODO DRY!!
    },
    credentials: "include",
    body: JSON.stringify({
      ...json,
      csrf: localStorage.getItem("serviceToken"),
    }),
  };
  return await fetch(api, otherParam) // API call
    .then((dataResp) => {
      return dataResp.json();
    }) // Format API response as JSON
    .then((res) => {
      const result = res["bk-result"];
      if (!result) {
        console.log("오류:" + JSON.stringify(res));
      }
      if (callback != null) callback(result, res);
      return { result, response: res };
    })
    .catch((error) => {
      console.log(error);
      return { result: false, response: null };
    });
}
