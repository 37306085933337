import React, { forwardRef } from "react";

const TermsOfUse = forwardRef((props, ref) => {
  return (
    <div>
        <div className="personal-info">
            <h1>이용약관</h1>
            <div className="pp-wrap">
                <div className="pri-box">
                    <b>제1조 (목적)</b>
                    <p>이 약관는 닥터 잉글리쉬가 운영하는 https://www.dr-english.com (이하 "닥터 잉글리쉬"이라 한다)에서 제공하는 
                        인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 닥터잉글리쉬와 회원의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.
                    </p>
                </div>        

                <div className="pri-box">
                    <b>제2조 (용어정의)</b>
                    <ol>
                        <li>"닥터잉글리쉬" : 1대 1 영어 화상 서비스. </li>
                        <li>회원"이라 함는 "닥터잉글리쉬"에 개인정보를 제공하여 회원등록을 한 자로서, "닥터잉글리쉬"의 정보를 지속적으로 제공받으며, 
                            "닥터잉글리쉬"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
                        </li>
                    </ol>
                </div>

                <div className="pri-box">
                    <b>제3조 (약관 등의 명시와 설명 및 개정)</b>
                    <ol>
                        <li>"닥터잉글리쉬"는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 
                            전화번호,  자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 회원이 쉽게 알 수 있도록 닥터잉글리쉬의 초기 
                            서비스화면(전면)에 게시합니다. 다만, 약관의 내용는 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다</li>
                        <li>"닥터잉글리쉬"는 전자상거래 등에서 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망
                            이용촉진 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                        </li>
                        <li>"닥터잉글리쉬"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 닥터잉글리쉬의 초기화면에 그 적용일자
                            7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을
                            두고 공지합니다. 이 경우 "닥터잉글리쉬"는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
                        </li>
                        <li>"닥터잉글리쉬"이 약관을 개정할 경우에는 그 개정약관는 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 
                            대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 
                            의한 개정약관의 공지기간 내에 "닥터잉글리쉬"에 송신하여 "닥터잉글리쉬"의 동의를 받는 경우 에는 개정약관 조항이 적용됩니다.
                        </li>
                        <li>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법률, 약관의규제등에관한법률,
                            공정거래위원회가 정하는 전자상거래 등에서의소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
                        </li>
                    </ol>
                </div>

                <div className="pri-box">
                    <b>제4조 (서비스의 제공 및 변경)</b>
                    <ol>
                        <li>"닥터잉글리쉬"는 다음과 같는 업무를 수행합니다. (1:1화상 교육 서비스 및 관련 정보 제공/ 기타 "닥터잉글리쉬"가 정하는 업무)</li>
                        <li>"닥터잉글리쉬"는 강습 서비스의 변경 또는 오류 의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스를 변경할 수 있습니다. 
                            이 경우에는 변경된 서비스 내용 및 제공일자를 명시하여 현재의 서비스 내용을 게시한 곳에 즉시 공지합니다.
                        </li>
                        <li>전항의 경우 "닥터잉글리쉬"는 이로 인하여 회원이 입는 손해를 배상합니다. 다만, "닥터잉글리쉬"가 고의 또는 과실이 없음을 입증하는
                             경우에는 그러하지 아니합니다.
                        </li>
                    </ol>
                </div>

                <div className="pri-box">
                    <b>제5조 (서비스의 중단)</b>
                    <ul className="circle-num">
                        <li>"닥터잉글리쉬"는 컴퓨터 등 정보통신설비의 보수점검,교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                        <li>"닥터잉글리쉬"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원 또는 제3자가 입는 손해에 대하여 배상합니다. 단, "닥터잉글리쉬"가 고의 
                            또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                        <li>사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "닥터잉글리쉬"는 제8조에 정한 방법으로 회원에게 통지하고 
                            당초 "닥터잉글리쉬"에서 제시한 조건에 따라 소비자에게 보상합니다.</li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제6조 (회원가입)</b>
                    <ul className="circle-num bar-txt">
                        <li>회원는 "닥터잉글리쉬"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</li>
                        <li>"닥터잉글리쉬"는 제1항과 같이 회원으로 가입할 것을 신청한 회원 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
                            <span>타인 명의의 신청</span>
                            <span>등록 내용에 허위, 기재누락, 오기가 있는 경우</span>
                            <span>기타 회원으로 등록하는 것이 "닥터잉글리쉬"의 기술상 현저히 지장이 있다고 판단되는 경우</span>
                            <span>이용 신청 고객의 귀책사유로 이용승낙이 곤란한 경우</span>
                        </li>
                        <li>회원가입계약의 성립시기는 "닥터잉글리쉬"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                        <li>회원는 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "닥터잉글리쉬"에 대하여 그 변경사항을 알려야 합니다.</li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제7조 (회원 탈퇴 및 자격 상실 등)</b>
                    <ul className="circle-num bar-txt">
                        <li>회원는 "닥터잉글리쉬"에 언제든지 탈퇴를 요청할 수 있으며 "닥터잉글리쉬"는 즉시 회원탈퇴를 처리합니다.</li>
                        <li>회원이 다음 각호의 사유에 해당하는 경우, "닥터잉글리쉬"는 회원자격을 제한 및 정지시킬 수 있습니다.
                            <span>가입 신청 시에 허위 내용을 등록한 경우</span>
                            <span>"닥터잉글리쉬"를 이용하여 구입한 재화 등의 대금, 기타 "닥터잉글리쉬"이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</span>
                            <span>다른 사람의 "닥터잉글리쉬" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</span>
                            <span>"닥터잉글리쉬"를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span>
                        </li>
                        <li>"닥터잉글리쉬"가 회원자격을 상실시키는 경우에는 7일간의 소명 기회를 드립니다. 본인의 의도와 상관없이 회원 정보가 삭제되는 것을 방지하기 
                            위한 조치 이므로 해당 기간 안에 관련 사항에 대한 의견을 닥터잉글리쉬에 개진하고 소명의지가 없거나 소명 기회를 무시하였을 경우에는 회원등록을 말소합니다.
                        </li>
                    </ul>
                </div>
                <div className="pri-box">
                    <b>제8조 (회원에 대한 통지)</b>
                    <ul className="circle-num bar-txt">
                        <li>"닥터잉글리쉬"가 회원에 대한 통지를 하는 경우, 회원이 "닥터잉글리쉬"와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</li>
                        <li>"닥터잉글리쉬"는 불특정다수 회원에 대한 통지의 경우 일주일이상 "닥터잉글리쉬" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
                             다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제9조 (서비스 신청)</b>
                    <p>"닥터잉글리쉬"회원는 "닥터잉글리쉬" 상에서 다음 또는 이와 유사한 방법에 의하여 서비스를 신청하며, "닥터잉글리쉬"는 회원이 서비스 이용 함에
                        있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                    </p>
                        <ol>
                            <li>- 서비스 이용 및 선택</li>
                            <li>- 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                            <li>- 약관내용, 환불권이 제한되는 서비스 등에 대한 확인</li>
                            <li>- 결제방법의 선택</li>
                        </ol>
                </div>

                <div className="pri-box">
                    <b>제10조 (서비스 이용의 성립)</b>
                    <ul className="circle-num num-txt">
                        <li>"닥터잉글리쉬"는 제9조와 같는 서비스 신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다.
                            <span>신청 내용에 허위, 기재누락, 오기가 있는 경우</span>
                            <span>기타 서비스 신청에 승낙하는 것이 "닥터잉글리쉬" 기술상 현저히 지장이 있다고 판단하는 경우</span>
                        </li>
                        <li>"닥터잉글리쉬"의 수신확인통지형태로 회원에게 도달한 시점에 서비스 신청이 성립한 것으로 봅니다.</li>
                        <li>"닥터잉글리쉬"의 승낙의 의사표시에는 회원의 서비스 신청에 대한 확인 및 서비스 신청의 정정/취소 등에 관한 정보 등을 포함하여야 합니다.</li>
                        <li>"닥터잉글리쉬 문서/서식 무료다운로드" 프로그램과 관련한 권리는 닥터잉글리쉬에 있으며 회원에게 소유권을 준 것이 아니라 
                            이용허락을 준 것이므로 상업적인 용도 재배포 혹는 불법적인 방법으로 조작하는 것을 금지합니다.</li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제11조 (지급방법)</b>
                    <p>"닥터잉글리쉬"에서 구매한 컨텐츠 또는 1:1 화상 수업에 대한 대금지급방법는 다음 각호의 방법중 가용한 방법으로 할 수 있습니다. 
                        단, "닥터잉글리쉬"는 회원의 지급방법에 대하여 컨텐츠 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                    </p>
                        <ol>
                            <li>- 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
                            <li>- 신용카드 등의 각종 카드 결제</li>
                            <li>- 온라인무통장입금</li>
                            <li>- 기타 전자적 지급 방법에 의한 대금 지급 등</li>
                        </ol>
                </div>

                <div className="pri-box">
                    <b>제12조 (서비스 공급)</b>
                    <ul className="circle-num num-txt">
                        <li>"닥터잉글리쉬"는 회원와 서비스 공급시기에 관하여 별도의 약정이 없는 결제 승인이 있는 날부터 서비스 등을 이용할 수 있도록 필요한 조치를 취합니다.</li>
                        <li>"닥터잉글리쉬"는 서비스 대해 이용방법, 관련 프로그램, 이용 안내 등을 명시합니다.</li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제13조 (요금 환불)</b>
                    <p>회사와 구매에 관한 계약을 체결한 회원은 아래와 같이 결제에 대한 취소 및 환불을 요구할 수 있습니다. 
                        이용 계약의 신청 후 회사로부터의 상담이 제공되지 않은 경우 결제 취소가 가능합니다.
                    </p>
                        <ol type="A" className="bar-txt">
                            <li>결제회원 : 7일 이내에 수업을 듣지 않은 경우 100% 환불이 가능합니다.</li>
                            <li>수업을 1회 진행한 경우에는 환불이 50%가 가능합니다.</li>
                            <li>수업을 3회 이상 들은 경우 환불이 불가능합니다.
                                <span>상담후, 각 조건에 따라 환불규정이 달라질 수 있습니다.</span>
                                <span>회사의 귀책 사유로 결제오류가 발생한 경우</span>
                                <span>회사의 귀책 사유로 서비스가 중단된 경우</span>
                                <span>본 조의 환불금액 기준은 각 회원마다 다르다보니 상담 후 환불 받는 것을 요구합니다.</span>
                            </li>
                        </ol>
                </div>

                <div className="pri-box">
                    <b>제14조 (서비스 철회)</b>
                    <ul className="circle-num num-txt">
                        <li>"닥터잉글리쉬"와 컨텐츠 사용에 관한 계약을 체결한 회원는 서비스 신청 승인을 받는 날부터 7일 이내에는 서비스 철회 신청을 할 수 있습니다.
                            단 "14조" 범위에 벗어나지 않는 경우에 한하며 이용일수에 해당하는 금액을 공제하고 환급하기로 합니다.</li>
                        <li>회원이 제공받는 컨텐츠 및 서비스가 회원이 원하지 않는 정보일 경우에도 위1항과 같이 7일 이내에 서비스 철회 신청을 할 수 있습니다.</li>
                        <li>어떤 이유로든 회원의 정당한 사유에 의해 서비스 철회 요구시 사용자가 이용한 신용카드 또는 전자화폐 결제수단으로 컨텐츠 등의 대금을 
                            지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정시 또는 취소하도록 요청할 수 있습니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제15조 (서비스 철회 환급 기준)</b>
                    <ul className="circle-num">
                        <li>법정 대리인의 동의 없이 미성년자와 계약에 대한 서비스 철회 요청시 "닥터잉글리쉬"는 계약 철회에 응하며 기 납부한 요금는 전액 
                            환급조치하고 미납요금 및 위약금 청구행위를 하지 않습니다.</li>
                        <li>허위, 과장광고에 의한 이용계약 "닥터잉글리쉬"는 회원이 제시한 사유가 서비스 사용규정에 의거 합당하다고 판단될 경우 지체 없이
                            서비스 철회 요구에 응하며 이용료 전액을 환급하기로 합니다.</li>
                        <li>소비자의 귀책사유로 인한 계약해지 시 "닥터잉글리쉬"는 해지일까지의 이용일수에 해당하는 금액을 공제 후 환불해드립니다.</li>
                        <li>3일 이상 서비스가 중지되거나 장애가 발생한 경우 "닥터잉글리쉬"는 계약해지 및 잔여기간에 대한 이용료를 환급해 드리기로 합니다.
                        단 서비스 중지, 장애시간는 소비자가 회사에 통지한 후부터 계산하되, 서비스가 불가항력(천재지변 등)이나 업체의 사전고지 또는 과실로 인하여 중지되거나 장애가
                        발생한 경우에는, 서비스 중지, 장애시간 계산에서 제외됨을 원칙으로 합니다.
                        </li>
                    </ul>
                </div>


                <div className="pri-box">
                    <b>제16조 ("닥터잉글리쉬"의 의무)</b>
                    <ul className="circle-num">
                        <li>"닥터잉글리쉬"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를제공하는데 
                            최선을 다하여야 합니다.</li>
                        <li>"닥터잉글리쉬"는 회원이 안전하게 인터넷 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</li>
                        <li>"닥터잉글리쉬"의  상품이나 서비스에 대하여 「표시,광고의공정화에관한법률」 제3조 소정의 부당한 표시,광고행위를 함으로써 회원이 손해를 입는 때에는 이를 배상할 책임을 집니다.</li>
                        <li>"닥터잉글리쉬"는 회원이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제17조 (회원의 ID 및 비밀번호에 대한 의무)</b>
                    <ul className="circle-num">
                        <li>제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임는 회원에게 있습니다.</li>
                        <li>회원는 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.</li>
                        <li>회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "닥터잉글리쉬"에 통보하고 "닥터잉글리쉬"의 안내가 
                            있는 경우에는 그에 따라야 합니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제18조 (회원의 의무)</b>
                    <p>회원는 다음 행위를 하여서는 안되며, "닥터잉글리쉬"는 서비스 이용 제한 조치를 취할 수 있습니다.</p>
                    <ol>
                        <li>신청 또는 변경 시 허위 내용의 등록</li>
                        <li>타인의 정보 도용</li>
                        <li>"닥터잉글리쉬"에 게시된 정보의 변경</li>
                        <li>"닥터잉글리쉬"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                        <li>"닥터잉글리쉬" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                        <li>"닥터잉글리쉬" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                        <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 닥터잉글리쉬에 공개 또는 게시하는 행위</li>
                        <li>"닥터잉글리쉬" 사이트 운영을 방해하는 트래픽 증가 행위</li>
                    </ol>
                </div>

                <div className="pri-box">
                    <b>제19조 (저작권의 귀속 및 이용제한)</b>
                    <ul className="circle-num bar-txt">
                        <li>"닥터잉글리쉬"가 작성한 저작물에 대한 저작권 기타 지적재산권는 "닥터잉글리쉬"에 귀속합니다.
                            <span>닥터잉글리쉬의 사전 허가 없이 닥터잉글리쉬의 서비스 저작물을 직, 간접적으로 변조, 복사, 배포, 출판, 전시, 판매 하거나 상품제작,
                                 인터넷, 모바일 및 데이터베이스를 비롯한 각종 정보서비스 등에 사용하는 것을 금합니다.</span>    
                        </li>
                        <li>닥터잉글리쉬의 서비스에 대하여 회원이 직접 게시물을 작성할 때는 다음과 같는 내용을 준수하여서 제3자의 지적재산권을 침해하는 일이 없도록 하여야 합니다.
                            <span>게시물의 내용에 인용문이 포함되어 있을 경우, 그 인용문이 회원이나 사용자의 의견에 일부 참고된 것이 아니라 내용의 중심이라면 출처를 밝히더라도 
                                저작권 침해에 해당됩니다. 따라서 원 저작권자의 명시적 동의 없이 창작물을 게시해서는 안 됩니다.</span>    
                            <span>원 저작권자의 명시적 동의 없이 원 저작권자가 만든 콘텐츠의 전부 혹는 일부를 게시, 전재, 복사, 재배포, 변조, 판매, 게시하는 것은 출처를 밝히더라도
                                 저작권 침해에 해당합니다. 따라서 원 저작권자의 명시적 동의 없이 이런 게시물을 게시하거나 판매해서는 안됩니다.</span>
                            <span>회원 또는 제휴회사에서 등록한 게시자료에 대하여 제3자로부터 저작권 및 기타 권리의 침해 또는 명예훼손, 음란성 등의 이유로 이의가 제기된 경우, 
                                회사는 당해 게시물을 임시 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 
                                이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수 있습니다. 닥터잉글리쉬는 어떠한 경우도 제3자와 회원간, 또는 제3자와 
                                제휴회사간의 법적인 문제에 대한 책임을 지지 않습니다.</span>
                        </li>
                    </ul>
                </div>
                

                <div className="pri-box">
                    <b>제20조 (서비스 면책사항)</b>
                    <ul className="circle-num">
                        <li>천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 닥터잉글리쉬 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다</li>
                        <li>닥터잉글리쉬는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.</li>
                        <li>닥터잉글리쉬는 기간통신사업자가 전기통신역무의 제공을 중지하거나 정상적으로 제공하지 아니하여 서비스를 제공하지 못한 경우에는 그에 대한 책임을 부담하지 않습니다.</li>
                        <li>닥터잉글리쉬는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</li>
                        <li>회원는 닥터잉글리쉬 서비스와 관련하여 자신이 회사에 등록한 필수등록 항목(이동전화번호, 전자우편주소 등) 및 비밀번호의 보안에 대하여 책임을 지며, 닥터잉글리쉬는 
                            회원의 고의나 과실로 인하여 아이디, 비밀번호 등의 회원정보가 유출되어 발생하는 손해에 대하여는 책임을 지지 않습니다.</li>
                        <li>닥터잉글리쉬는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 회원이 신상정보 및 전자우편주소를 부실하게 기재하여 손해가 발생한 경우에는 책임을 지지 않습니다.</li>
                        <li>닥터잉글리쉬는 회원 상호간 및 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.</li>
                        <li>닥터잉글리쉬 사이트에 연결되거나 제휴한 업체(타 온라인사이트)에 포함되어 있는 내용의 유효성, 적합성, 법적 합리성, 저작권 준수 여부 등에 책임을 지지 않으며, 이로 인한
                            어떠한 손해에 대하여도 책임을 지지 않습니다.</li>
                        <li>닥터잉글리쉬 서비스(제휴관계 포함)를 통하여 제공되는 각종 문서서식 샘플는 문서작성을 위한 참고자료 용도로 제공되는 것이므로 자료활용에 따라 발생할 수 있는 손실 또는 
                            손해는 이용회원의 책임이며 닥터잉글리쉬 이에 대해 어떠한 책임도 지지 않습니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제21조 (분쟁해결)</b>
                    <ul className="circle-num">
                        <li>"닥터잉글리쉬"는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상 처리 기구를 설치,운영합니다.</li>
                        <li>"닥터잉글리쉬"는 회원로부터 제출되는 불만사항 및 의견는 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 
                            처리일정을 즉시 통보해 드립니다.</li>
                        <li>"닥터잉글리쉬"와 회원간에 발생한 전자상거래 분쟁과 관련하여 회원의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 
                            분쟁조정기관의 조정에 따를 수 있습니다.</li>
                        <li>"닥터잉글리쉬"의 컨텐츠를 상업적으로 재이용하거나 제3자에게 납품하여 이익을 취하는 경우 법정 대리인을 통한 저작권리를 회원에게 청구할 수 있고, 
                            소를 통하여 제기될 경우 저작권법에 따라 피해액을 청구합니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제22조 (휴면 계정 및 내용 폐기)</b>
                    <ol>
                        <li>1년 동안 닥터 잉글리쉬의 서비스를 이용하지 않으면 휴면 계정으로 전환 됩니다. </li>
                        <li>휴면 계정의 정보는 휴면 계정 시점에서 1년 동안 개인 정보를 소지 하고 있다가 폐지 됩니다.</li>
                        <li>휴면 계정에서 활성화에 대한 부분은 관리자가 설정 할 수 있으니 관리자한테 문의바랍니다. </li>
                    </ol>
                </div>

                <div className="pri-box">
                    <b>제23조 (재판권 및 준거법)</b>
                    <ul className="circle-num">
                        <li>"닥터잉글리쉬"와 회원간에 발생한 전자상거래 분쟁에 관한 소송는 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의
                             전속관할로 합니다. 다만, 제소 당시 회원의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</li>
                        <li>"닥터잉글리쉬"와 회원간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
                        </li>
                    </ul>
                </div>

                <div className="pri-box">
                    <b>제24조 (기타조항)</b>
                    <ul className="circle-num">
                        <li>약관을 위반하거나 서비스 이용 시, 불편사항이 있을 경우, 고객지원센터로 문의 바랍니다.</li>
                    </ul>
                    <p>문의전화: [대표] 1811-6047 (평일 09:00~17:30, 점심시간 11:50~13:00)</p>
                </div>



        
            </div>



        </div>
    </div>
  );
});

export default TermsOfUse;
