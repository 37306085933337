import React, { useEffect, useState } from "react";
import "../../../assets/main.css";
import "../../../assets/member.css";
import { certificateUrl, requestOpen } from "network/Request";

const FindId = () => {
  const [certJSON, setCertJSON] = useState();
  const [loginId, setLoginId] = useState();

  const receive = (e) => {
    if (e.data["kmc_cert"]) {
      let certJSON = {
        kmc_cert: e.data["kmc_cert"],
        kmc_cert_num: e.data["cert_num"],
      };
      setCertJSON(certJSON);
      requestFindId(certJSON);
    }
  };

  const requestFindId = (certJSON) => {
    requestOpen({ "bk-working": "find-id", ...certJSON }, (result, response) => {
      console.log(response);
      if (result) {
        setLoginId(response['login_id']);
      }
    });
  }

  useEffect(() => {
    window.addEventListener("message", receive, false);
    return () => {
      window.removeEventListener("message", receive);
    };
  }, []);

  return (
    <div>
      <div className="container">
        <div className="login_box">
          <div className="member-top">
            <h1>
              Hello!<br></br>Please Login!
            </h1>
            <span>아이디 찾기</span>
          </div>
          {!certJSON && (
            <div className="identification">
              <button
                className="id-btn"
                onClick={() => {
                  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                width=0,height=0,left=-1000,top=-1000`;
                  window.open(certificateUrl, "본인인증", params);
                }}
              >
                본인인증
              </button>
              <p>본인 인증을 통해 아이디를 찾을 수 있습니다.</p>
            </div>
          )}
          {loginId && (
            <div className="identification">
              <h1>
                가입하신 아이디는
              </h1>
              <span>{loginId} 입니다.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindId;
