import React, { useState } from "react";

import "../../assets/main.css";
import "../../assets/sub.css";
import { Modal } from "@mui/material";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsOfUse from "../TermsOfUse";

const Footer = () => {
  const [privacyopen, setPrivacyopen] = useState(false);
  const [termsopen, setTermsopen] = useState(false);
  const handlePrivacyopen = () => {
    setPrivacyopen(true);
  };
  const handlePrivacyClose = () => {
    setPrivacyopen(false);
  };
  const handleTermsopen = () => {
    setTermsopen(true);
  };
  const handleTermsClose = () => {
    setTermsopen(false);
  };

  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-info flex">
            <b className="block">Dr. English</b>
            <div className="footer-btn">
              <a onClick={handlePrivacyopen}>개인정보처리방침</a>
              <a onClick={handleTermsopen}>이용약관</a>
            </div>
          </div>

          <div className="footer-in">
            <span>G.E.S에이전트</span>
            <span>대표: 강은영</span>
            <span>울산광역시 북구 천곡남로 38, 410동 101호</span>
            <span>대표번호: 1811-6047</span>
          </div>
          <div className="footer-in">
            <span>통신판매업신고번호: 제2023-울산북구-0645호</span>
            <span>사업자등록번호: 610-09-32035</span>
            <span>계좌번호: 신한 110-467-100057 강은영</span>
          </div>

          <div className="footer-copy">
            &copy; Dr. English All Rights Reserved.
          </div>
        </div>
      </footer>

      <Modal open={privacyopen} onClose={handlePrivacyClose}>
        <div className="modal">
          <button className="close-btn" onClick={handlePrivacyClose}>
            닫기
          </button>
          <PrivacyPolicy />
        </div>
      </Modal>

      <Modal open={termsopen} onClose={handleTermsClose}>
        <div className="modal">
          <button className="close-btn" onClick={handleTermsClose}>
            닫기
          </button>
          <TermsOfUse />
        </div>
      </Modal>
    </div>
  );
};

export default Footer;
