import { FloatingActionButton } from "components/FloatingActionButton";
import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";
import MainHeader from "../../../components/Header/MainHeader";
import SideNavBar from "../../../components/snb/SideNavBar";
// import TopBtn from "components/TopBtn";

const MainLayout = () => {
  return (
    <div>
      <FloatingActionButton />
      <MainHeader />
      <SideNavBar />
      <Outlet />
      <Footer />
      {/* <TopBtn /> */}
    </div>
  );
};

export default MainLayout;
