import React, { useCallback, useEffect, useState } from "react";
import "../../../assets/main.css";
import "../../../assets/sub.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import { requestOpen } from "network/Request";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const TeacherInfo = () => {
  const [teacherArray, setTeacherArray] = useState([]);
  const [slides, setSlides] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(getView());

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(getView());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getView() {
    const screenWidth = window.innerWidth;
    return screenWidth > 768 ? 2 : 1;
  }

  useEffect(() => {
    requestOpen(
      {
        "bk-working": "listing-open-teacher",
      },
      (result, response) => {
        if (result) {
          console.log(response["teacher_array"]);
          setTeacherArray(response["teacher_array"]);
        }
      }
    );
  }, []);

  useEffect(() => {
    const convertedSlides = teacherArray.map((teacher) => {
      return {
        id: teacher.teacher_id,
        image: teacher.profile,
        name: teacher.user_name,
        country: teacher.country,
        word_top: teacher.one_line_comment,
        word_bottom: teacher.comment,
      };
    });
    setSlides(convertedSlides);
  }, [teacherArray]);

  return (
    <div className="teacher-info">
      <div className="sub-top">
        <div className="sub-bg teacher">
          <div className="sub-top-txt">
            Our
            <br />
            TEACHERS
            <span className="block">
              Dr. English uses ONLY
              <br />
              <u>Native English speakers</u>
            </span>
          </div>
        </div>
      </div>

      <div className="sub-con">
        <div className="teacher-wrap">
          <Swiper
            navigation
            pagination
            slidesPerView={slidesPerView}
            autoplay={{ delay: 5000 }}
          >
            {slides.map((slide, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="teacher-page">
                    <div className="teacher-profile">
                      <div className="teacher-pic">
                        <img src={slide.image} alt={i} />
                      </div>
                      <div className="teacher-name">
                        {slide.name}
                        <span className="country">{slide.country}</span>
                      </div>
                    </div>

                    <div className="teacher-word">
                      <div className="teacher-word-top">{slide.word_top}</div>
                      <div className="teacher-word-bttom">
                        <p> {slide.word_bottom}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="logo-bg">
          <div className="container">
            Dr. English teaches <br />
            <span className="teach"> accurate grammar</span> and
            <span className="teach"> pronunciation. </span>
            <br /> <br />
            닥터 잉글리쉬는 <span className="teach"> 정확한 문법</span>과
            <span className="teach"> 억양</span>을 추구합니다.
          </div>
        </div>
      </div>
      {/*sub_con*/}
    </div>
  );
};

export default TeacherInfo;
