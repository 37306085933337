import { request } from "network/Request";
import React, { useEffect, useState } from "react";
import "../../../../assets/sub.css";
import { Grid } from "@mui/material";
const UserPay = () => {
  const [paymentArray, setPaymentArray] = useState([]);

  useEffect(() => {
    request(
      { "bk-working": "listing-payment", limit_range: 50 },
      (result, response) => {
        console.log(result, response);
        setPaymentArray(response["limit_array"]);

        if (!result) {
          alert("오류");
        } else {
          console.log(response);
        }
      }
    );
  }, []);

  function convertedDate(millisec) {
    const date = new Date(millisec);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
    };
    return date.toLocaleString("ko-KR", options);
  }

  const moveToReceipt = (address) => {
    window.open(address, "_blank");
  };

  return (
    <div className="user_pay_form">
      {paymentArray.map((item, index) => (
        <div key={index}>
          <Grid container className="pay-list">
            <Grid item md={10} xs={12}>
              <span>
              <strong>{index + 1})</strong> <strong>일자 : </strong>
                {convertedDate(item["payment_millisec"])}
              </span>
              <span>
                <strong>상품명 : </strong>
                {item["payment_name"]} ({item["total_price"]}원)
              </span>
            </Grid>

            <Grid item md={2} xs={12}>
              <button
                onClick={() => moveToReceipt(item["receipt"])}
                className="receipt_btn"
              >
                영수증
              </button>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default UserPay;
