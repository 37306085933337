import { request, requestLogout, requestToken } from "network/Request";
import { createContext, useEffect, useReducer } from "react";

const setSession = (csrfToken, userName, expireTime, userExternalId) => {
  console.log(csrfToken, userName, expireTime, userExternalId);
  if (csrfToken && expireTime && userExternalId) {
    localStorage.setItem("drenglishCsrfToken", csrfToken);
    localStorage.setItem("drenglishUserName", userName);
    localStorage.setItem("drenglishExpireTime", expireTime);
    localStorage.setItem("drenglishUserExternalId", userExternalId);
  } else {
    localStorage.removeItem("drenglishCsrfToken");
    localStorage.removeItem("drenglishUserName");
    localStorage.removeItem("drenglishExpireTime");
    localStorage.removeItem("drenglishUserExternalId");
  }
};

const initialState = {
  isInitialized: false,
  isLoggedIn: false,
  user: null,
};

export const LoginContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
});

const accountReducer = (state, action) => {
  switch (action.type) {
    case "account_init": {
      const { isLoggedIn, user } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        user,
      };
    }
    case "login": {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        user,
      };
    }
    case "logout": {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const digestMessage = async (message) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return await window.crypto.subtle.digest("SHA-256", data);
  };

  const hexString = (buffer) => {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      const paddedHexCode = hexCode.padStart(2, "0");
      return paddedHexCode;
    });

    return hexCodes.join("");
  };

  const login = async (keepLogin, type, email, password, token) => {
    const digestValue = await digestMessage(`drenglish${password}`); // Hashing;
    const hashed = hexString(digestValue);
    let data = {
      // Might be able to reduce extra code (DRY method)
      "bk-working": "login", //will change for some calls
      keep_login: keepLogin,
      login_type: type,
      thirdparty_token: token,
      login_id: email,
      password: hashed.toLowerCase(),
    };
    const { result, res } = await requestToken(data, (result, receive) => {
      console.log(receive);
      if (result) {
        var csrf = receive["csrf_token"];
        var userName = receive["user_name"];
        var expireTime = new Date(receive["user_token_json"]["expire_time"]);
        var userExternalId = receive["user_external_id"];

        let user = { userName, csrf, userExternalId };

        setSession(csrf, userName, expireTime, userExternalId);
        dispatch({
          type: "login",
          payload: {
            user,
          },
        });
      } else {
        alert(receive["bk-result-detail"]);
      }
    });
    return { result, res };
  };

  const logout = async () => {
    await requestLogout({ "bk-working": "check-login" });
    setSession(null);
    dispatch({ type: "logout" });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { result } = await request({ "bk-working": "check-login" });
        if (result) {
          const csrfToken = window.localStorage.getItem("drenglishCsrfToken");
          const userName = window.localStorage.getItem("drenglishUserName");
          const userExternalId = window.localStorage.getItem(
            "drenglishUserExternalId"
          );
          const user = { userName, csrfToken, userExternalId };
          dispatch({
            type: "account_init",
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "account_init",
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "account_init",
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    };

    init();
  }, []);

  return (
    <LoginContext.Provider value={{ ...state, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};
