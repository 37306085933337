import React, { useEffect, useState } from "react";
import WOW from "wowjs";

import greeting from "../../../assets/images/gr_img.png";
import flow1 from "../../../assets/images/flow1.png";
import flow2 from "../../../assets/images/flow2.png";
import flow3 from "../../../assets/images/flow3.png";
import flow4 from "../../../assets/images/flow4.png";

import "../../../assets/main.css";
import "../../../assets/member.css";
import { Modal } from "@mui/material";
import LevelTable from "components/LevelTable";

const Home = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div className="wrap">
      <div className="main-visual">
        <div className="main-txt-wrap  wow fadeIn">
          <div id="arc-txt">
            <span className="t0">-</span>
            <span className="t1">약</span>
            <span className="t2">은</span>
            <span className="t3"> </span>
            <span className="t4">약</span>
            <span className="t5">사</span>
            <span className="t6">에</span>
            <span className="t7">게</span>
            <span className="t8">,</span>
            <span className="t9">병</span>
            <span className="t10">은</span>
            <span className="t11"> </span>
            <span className="t12">의</span>
            <span className="t13">사</span>
            <span className="t14">에</span>
            <span className="t15">게</span>
            <span className="t16">-</span>
          </div>
          <div className="main-txt">
            <h1>
              영어는?<span>Dr. English</span>에게!
            </h1>
          </div>
        </div>
      </div>

      <div className="greeting wow fadeInUp">
        <div className="container flex">
          <div className="gr-img">
            <img src={greeting} alt="닥터잉글리시 소개" />
          </div>
          <div className="gr-txt">
            <h2 className="sec-tit">
              <span className="set-tit-small block">
                최상의 온라인 영어 전문 교육 서비스
              </span>
              <span className="colorBlue">‘Dr. English’</span>에<br />
              오신 것을 환영합니다.
            </h2>
            <p>
              Dr. English 는 원어민 강사진이 기초부터 고급까지 여러분의 영어
              학습을 집중적으로 관리합니다.
            </p>
            <p>
              최적화된 시스템으로 여러 선생님들과 일대일 화상영어 수업이
              가능합니다.
            </p>
            <p>
              Dr. English에서는 여러분들의 목표와 꿈을 달성하도록 최선을 다하고
              있습니다.
            </p>
          </div>
        </div>
      </div>

      <div className="advantages wow fadeInUp">
        <div className="container flex">
          <h2 className="sec-tit">
            왜,<span className="colorBlue block">‘Dr. English’</span>일까요 ?
          </h2>

          <div className="ad-flow-wrap">
            <div className="ad-flow">
              <div className="flow">
                <img src={flow1} alt="유연한 수업신청" />
                <p>유연한 수업신청</p>
                <i className="wow flash"></i>
              </div>
              <div className="flow">
                <img src={flow2} alt="원어민과 1:1수업" />
                <p>원어민과 1:1수업</p>
                <i className="wow flash2"></i>
              </div>
            </div>
            <div className="ad-flow">
              <div className="flow">
                <img src={flow4} alt="철저한 복습" />
                <p>철저한 복습</p>
                <i className="wow flash3"></i>
              </div>
              <div className="flow">
                <img src={flow3} alt="수업 후 피드백" />
                <p>수업 후 피드백</p>
                <i className="wow flash4"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cefr">
        <div className="container">
          <h2 className="sec-tit">
            <span className="colorBlue">‘Dr. English’</span>에서는{" "}
            <span className="colorBlue">CEFR</span>을 기준으로 합니다.
          </h2>
          <div className="cefr-bg">
            <p>
              <b>CEFR</b> - 유럽공통 언어평가 기준
            </p>
            <p>
              우리의 프로그램은 유럽언어공통기준(CEFR)에 기반을 둔 커리큘럼과
              개인 맞춤형 커리큘럼으로 구성되어 있습니다. <br /> 자유로운 수업
              예약과 다양한 모바일 기기를 통해 개인에게 편리한 시간과 장소를
              선택해서 수업을 들을 수 있습니다. <br /> 또한 CEFR평가자격증이
              주어지는 레벨 테스트가 제공됩니다.
            </p>
            <a onClick={handleOpen}>레벨표 확인하기</a>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className="modal">
          <button className="close-btn" onClick={handleClose}>
            닫기
          </button>
          <LevelTable open={open} handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
};

export default Home;
