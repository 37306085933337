import LoginBox from "components/Login/LoginBox";
import React from "react";
import "../../../assets/main.css";
import "../../../assets/member.css";
const Login = () => {
  return (
    <div>
      <LoginBox />
    </div>
  );
};

export default Login;
