import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";
import LoginHeader from "../../../components/Header/LoginHeader";
import SideNavBar from "../../../components/snb/SideNavBar";
// import TopBtn from "components/TopBtn";

const LoginLayout = () => {
  return (
    <div>
      <LoginHeader />
      <SideNavBar />
      <Outlet />
      <Footer />
      {/* <TopBtn /> */}
    </div>
  );
};

export default LoginLayout;
