import { request } from "network/Request";
import React, { useEffect } from "react";
import "../../../../assets/main.css";
import "../../../../assets/tab.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jspdfFont } from "pages/Class/Main/MalgunJSPDF";

const timezoneOffset = new Date().getTimezoneOffset() * 60000;

const MonthlyReport = () => {
  const [reportArray, setReportArray] = React.useState([{}]);

  useEffect(() => {
    request(
      {
        "bk-working": "listing-all-my-class-report",
        current_millisec: new Date().getTime(),
      },
      (result, response) => {
        if (result) {
          console.log(response);
          setReportArray(
            response.limit_array.map((item) => {
              const reports = item["reports"]?.filter(
                (item) => item.length === 7
              );
              var report = {
                credit_id: item.credit_id,
                user_name: item.user_name,
                group_name: item.group_name,
                creidt_type: item.credit_type,
                start_millisec: item.start_millisec,
                end_millisec: item.end_millisec,
                total_usage: item.total_usage,
              };
              report["attendances"] = new Array(22).fill("");
              report["speakings"] = new Array(22).fill("");
              report["pronunciations"] = new Array(22).fill("");
              report["grammars"] = new Array(22).fill("");
              report["vocabularys"] = new Array(22).fill("");
              report["listenings"] = new Array(22).fill("");
              report["overalls"] = new Array(22).fill("");
              for (var i in reports) {
                const reportString = reports[i];
                if (!reportString || reportString.length < 7) {
                  continue;
                }
                report["attendances"][i] = reportString.substring(0, 1);
                report["speakings"][i] =
                  reportString.substring(1, 2) === "A"
                    ? "10"
                    : reportString.substring(1, 2);
                report["pronunciations"][i] =
                  reportString.substring(2, 3) === "A"
                    ? "10"
                    : reportString.substring(2, 3);
                report["grammars"][i] =
                  reportString.substring(3, 4) === "A"
                    ? "10"
                    : reportString.substring(3, 4);
                report["vocabularys"][i] =
                  reportString.substring(4, 5) === "A"
                    ? "10"
                    : reportString.substring(4, 5);
                report["listenings"][i] =
                  reportString.substring(5, 6) === "A"
                    ? "10"
                    : reportString.substring(5, 6);
                report["overalls"][i] =
                  reportString.substring(6, 7) === "A"
                    ? "10"
                    : reportString.substring(6, 7);
              }
              return report;
            })
          );
        }
      }
    );
  }, []);

  const download = () => {
    const doc = new jsPDF({
      format: "a4",
      orientation: "landscape",
      unit: "cm",
    });

    doc.addFileToVFS("malgun.ttf", jspdfFont); //_fonts 변수는 Base64 형태로 변환된 내용입니다.
    doc.addFont("malgun.ttf", "malgun", "normal");
    doc.setFont("malgun");

    // Or use javascript directly:
    autoTable(doc, {
      head: [
        ["NO", "소속", "수업기간", "수업횟수", "수강자", "리포트"].concat(
          new Array(22).fill(0).map((_, index) => index + 1 + "")
        ),
      ],
      theme: "striped",
      styles: {
        lineWidth: 0.01,
        font: "malgun",
        fontSize: 7,
        fontStyle: "normal",
      },
      headStyles: {
        fontSize: 7,
        font: "malgun",
        fontStyle: "normal",
      },
      body: reportArray.map((item) => [
        item["credit_id"],
        item["group_name"],
        `${
          new Date(item["start_millisec"] ?? 0 + timezoneOffset)
            .toISOString()
            .split("T")[0]
        } ~ ${
          new Date(item["end_millisec"] ?? 0 + timezoneOffset)
            .toISOString()
            .split("T")[0]
        }`,
        item["total_usage"],
        item["user_name"],
        "attendance\nspeaking\npronunciation\ngrammar\nvocabulary\nlistening\noverall",
        ...new Array(22)
          .fill(0)
          .map(
            (_, index) =>
              item["attendances"][index] +
              "\n" +
              item["speakings"][index] +
              "\n" +
              item["pronunciations"][index] +
              "\n" +
              item["grammars"][index] +
              "\n" +
              item["vocabularys"][index] +
              "\n" +
              item["listenings"][index] +
              "\n" +
              item["overalls"][index]
          ),
      ]),
    });

    doc.text(100, 200, "테스트");

    doc.save("report.pdf");
  };

  const reportTable = (report, index) => {
    const cell = "1.7%";
    const createTr = (name, a) => {
      return (
        <tr className="tr">
          <td className="td">{name}</td>
          {a &&
            a.map((value, index) => {
              return (
                <td className="td" key={index}>
                  {value ?? ""}
                </td>
              );
            })}
        </tr>
      );
    };

    return (
      <table key={index} className="report_table">
        <colgroup>
          <col style={{ width: "3%" }}></col>
          <col style={{ width: "8%" }}></col>
          <col style={{ width: "9%" }}></col>
          <col style={{ width: "4%" }}></col>
          <col style={{ width: "7%" }}></col>
          <col style={{ width: "10%" }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
        </colgroup>
        <thead>
          <tr className="tr">
            <th className="th">No</th>
            <th className="th">소속</th>
            <th className="th">수업기간</th>
            <th className="th">수업횟수</th>
            <th className="th">수강자</th>
            <th className="th">Report</th>
            {new Array(22).fill(0).map((e, index) => (
              <th key={index} className="th">
                {index + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="tr">
            <td rowSpan="7">{report["credit_id"]}</td>
            <td rowSpan="7">{report["group_name"]}</td>
            <td rowSpan="7">
              {
                new Date(report["start_millisec"] ?? 0 + timezoneOffset)
                  .toISOString()
                  .split("T")[0]
              }
              <br />
              ~<br />
              {
                new Date(report["end_millisec"] ?? 0 + timezoneOffset)
                  .toISOString()
                  .split("T")[0]
              }
            </td>
            <td rowSpan="7">
              {report['total_usage']}
            </td>
            <td rowSpan="7">{report["user_name"]}</td>
            <td className="td">Attendance</td>
            {report.attendances &&
              report.attendances.map((value, index) => {
                return (
                  <td className="td" key={index}>
                    {value ?? ""}
                  </td>
                );
              })}
          </tr>
          {createTr("Speaking", report.speakings)}
          {createTr("Pronunciation", report.pronunciations)}
          {createTr("Grammar", report.grammars)}
          {createTr("Vocabulary", report.vocabularys)}
          {createTr("Listening", report.listenings)}
          {createTr("Overall", report.overalls)}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h1 className="rep-tit">
        월별 레포트
        <button onClick={() => download()} className="rep-down-btn">
          다운로드
        </button>
      </h1>
      <div className="report-wrap">
        {reportArray.map((item, index) => reportTable(item, index))}
      </div>
    </div>
  );
};

export default MonthlyReport;
