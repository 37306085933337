import { request } from "network/Request";
import React, { useEffect } from "react";
import "../../../../assets/tab.css";
import { dateDiff, ymdToDate } from "utils/dateutil";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jspdfFont } from "pages/Class/Main/MalgunJSPDF";

const timezoneOffset = new Date().getTimezoneOffset() * 60000;

const AttendanceReport = () => {
  const [reportArray, setReportArray] = React.useState([{}]);

  useEffect(() => {
    request(
      {
        "bk-working": "listing-all-my-class-report",
        current_millisec: new Date().getTime(),
      },
      (result, response) => {
        if (result) {
          console.log(response.limit_array);
          setReportArray(
            response.limit_array.map((item) => {
              var report = {
                credit_id: item.credit_id,
                user_name: item.user_name,
                creidt_type: item.credit_type,
                start_millisec: item.start_millisec,
                end_millisec: item.end_millisec,
                total_usage: item.total_usage,
              };
              let newDate = new Date(item.start_millisec + timezoneOffset);
              report["attendances"] = new Array(31).fill("");
              for (var i in item["ymds"]) {
                const index = dateDiff(ymdToDate(item["ymds"][i]), newDate);
                if (index >= 31) {
                  break;
                }
                const reportString = item["reports"][i];
                if (!reportString || reportString.length < 7) {
                  console.log(reportString);
                  continue;
                }
                report["attendances"][index] +=
                  (report["attendances"][index] ? "\n" : "") +
                  reportString.substring(0, 1);
              }
              return report;
            })
          );
        }
      }
    );
  }, []);

  const download = () => {
    const doc = new jsPDF({
      format: "a4",
      orientation: "landscape",
      unit: "cm",
    });

    doc.addFileToVFS("malgun.ttf", jspdfFont); //_fonts 변수는 Base64 형태로 변환된 내용입니다.
    doc.addFont("malgun.ttf", "malgun", "normal");
    doc.setFont("malgun");

    // Or use javascript directly:
    autoTable(doc, {
      head: [
        ["NO", "소속", "수업기간", "수업횟수", "수강자", "리포트"].concat(
          new Array(31).fill(0).map((_, index) => index + 1 + "")
        ),
      ],
      theme: "grid",
      styles: {
        lineWidth: 0.01,
        font: "malgun",
        fontSize: 7,
        fontStyle: "normal",
      },
      headStyles: {
        fontSize: 7,
        font: "malgun",
        fontStyle: "normal",
      },
      body: reportArray.map((item) => [
        item["credit_id"],
        item["group_name"],
        `${
          new Date(item["start_millisec"] ?? 0 + timezoneOffset)
            .toISOString()
            .split("T")[0]
        } ~ ${
          new Date(item["end_millisec"] ?? 0 + timezoneOffset)
            .toISOString()
            .split("T")[0]
        }`,
        item["total_usage"],
        item["user_name"],
        "attendance",
        ...new Array(31).fill(0).map((_, index) => item["attendances"][index]),
      ]),
    });

    doc.text(100, 200, "테스트");

    doc.save("report.pdf");
  };

  const reportTable = (report, index) => {
    const cell = "1.7%";
    return (
      <table key={index} className="report_table">
        <colgroup>
          <col style={{ width: "3%" }}></col>
          <col style={{ width: "8%" }}></col>
          <col style={{ width: "9%" }}></col>
          <col style={{ width: "4%" }}></col>
          <col style={{ width: "7%" }}></col>
          <col style={{ width: "10%" }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
          <col style={{ width: cell }}></col>
        </colgroup>
        <thead>
          <tr className="tr">
            <th className="th">No</th>
            <th className="th">소속</th>
            <th className="th">수업기간</th>
            <th className="th">수업횟수</th>
            <th className="th">수강자</th>
            <th className="th">Report</th>
            {new Array(31).fill(0).map((e, index) => (
              <th key={index} className="th">
                {index + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="tr">
            <td rowSpan="7">{report["credit_id"]}</td>
            <td rowSpan="7">{report["group_name"]}</td>
            <td rowSpan="7">
              {
                new Date(report["start_millisec"] ?? 0 + timezoneOffset)
                  .toISOString()
                  .split("T")[0]
              }
              <br />
              ~<br />
              {
                new Date(report["end_millisec"] ?? 0 + timezoneOffset)
                  .toISOString()
                  .split("T")[0]
              }
            </td>
            <td rowSpan="7">{report["total_usage"]}</td>
            <td rowSpan="7">{report["user_name"]}</td>
            <td className="td">Attendance</td>
            {report.attendances &&
              report.attendances.map((value, index) => {
                return (
                  <td className="td" style={{ whiteSpace: "pre" }} key={index}>
                    {value ?? ""}
                  </td>
                );
              })}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h1 className="rep-tit">
        기간별 레포트
        <button onClick={() => download()} className="rep-down-btn">
          다운로드
        </button>
      </h1>

      <div className="report-wrap">
        {reportArray.map((item, index) => reportTable(item, index))}
      </div>
    </div>
  );
};

export default AttendanceReport;
