import { useContext, useEffect, useState } from "react";
import { request } from "network/Request";
import { ymd } from "utils/dateutil";
import { CourseContext } from "controller/provider/CourseProvider";
import { koreanHours, koreanMinutes } from "tools/dateutil";
import { listingCredits } from "controller/CreditController";
import { useNavigate } from "react-router-dom";

export const FloatingActionButton = () => {
  const { courses } = useContext(CourseContext);
  const [currentClassJoin, setCurrentClassJoin] = useState();
  const [classDate, setClassDate] = useState("");
  const [startable, setStartable] = useState(false);
  const [myCreditArray, setMyCreditArray] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    request(
      {
        "bk-working": "current-class-join",
      },
      (result, response) => {
        if (result) {
          console.log(response);
          setCurrentClassJoin(response["current_join"]);
        }
      }
    );
  }, [courses]);

  useEffect(() => {
    listingCredits(new Date().getTime()).then(({ result, response }) => {
      if (result) {
        setMyCreditArray(response["limit_array"]);
      } else {
        alert("결제정보를 불러오지 못했습니다.");
      }
    });
  }, []);

  useEffect(() => {
    if (currentClassJoin && currentClassJoin["ymd"]) {
      let classMonth = currentClassJoin["ymd"].toString().substring(4, 6);
      let classDay = currentClassJoin["ymd"].toString().substring(6, 8);
      const convertedDate = classMonth + " / " + classDay;
      setClassDate(convertedDate);
    } else {
      setClassDate("");
    }
  }, [currentClassJoin]);

  useEffect(() => {
    setStartable(isStartable());
  }, [currentClassJoin]);

  const startMeeting = () => {
    if (isStartable()) {
      request(
        {
          "bk-working": "user-room-api",
          operation: "join",
          class_join_id: currentClassJoin["class_join_id"],
        },
        (result, response) => {
          if (result) {
            let url = response["url"];
            window.open(url);
          }
        }
      );
      return;
    }
    if (!myCreditArray) {
      alert("오류가 발생하였습니다. 새로고침을 한번 해주세요.");
    }
    if (myCreditArray.length === 0) {
      alert("사용 가능한 크레딧이 없습니다. 결제창으로 이동합니다.");
      navigate("/payment");
      return;
    }
    if (!isStartable()) {
      alert("수업 가능 시간이 아닙니다.");
      return;
    }
  };

  const isStartable = () => {
    if (!currentClassJoin) {
      return false;
    }

    let date = new Date();
    let currentYmd = ymd(date);

    if (parseInt(currentClassJoin["ymd"]) === parseInt(currentYmd)) {
      return (
        currentClassJoin["start_hhmm"] <
        (koreanHours(date) + 2) * 100 + koreanMinutes(date)
      );
    }
  };

  return (
    <>
      <div
        className={`course-start ${
          startable
            ? "startable"
            : !startable && currentClassJoin
            ? "not_startable"
            : (myCreditArray?.length ?? 0) === 0
            ? "no_credit"
            : "not_startable"
        }`}
        onClick={() => startMeeting()}
      >
        <div className="class_date">{classDate}</div>
        {currentClassJoin &&
          currentClassJoin["start_hhmm"].substring(0, 2) +
            ":" +
            currentClassJoin["start_hhmm"].substring(2, 4)}
        <span>
          {startable
            ? "수업시작"
            : !startable && currentClassJoin
            ? "수업대기"
            : !myCreditArray
            ? "불러오는중"
            : myCreditArray.length !== 0
            ? "예약없음"
            : "결제하기"}
        </span>
      </div>
    </>
  );
};
