import { LoginContext } from "controller/provider/LoginProvider";
import React, { useContext, useState } from "react";
import "../../assets/main.css";
import "../../assets/sub.css";

const LoginBox = () => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [keepLogin, setKeepLogin] = useState(false);
  const loginContext = useContext(LoginContext);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginContext.login(keepLogin, 0, loginId, password);
    }
  };

  return (
    <div className="container">
      <div className="login_box">
        <div className="member-top">
          <h1>
            Hello!<br></br>Please Login!
          </h1>
          <span>로그인</span>
        </div>

        <div className="login-form">
          <div className="form-group">
            <label htmlFor="inp_id">아이디</label>
            <input
              type="text"
              id="inp_id"
              required=""
              placeholder="ID"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inp_pw">비밀번호</label>
            <input
              type="password"
              id="inp_pw"
              required=""
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>
        </div>

        <div className="login-bottom">
          <div className="form-check">
            <input
              type="checkbox"
              id="check_btn1"
              checked={keepLogin}
              onChange={(e) => setKeepLogin(e.target.checked)}
            />
            <label htmlFor="check_btn1">
              <span>로그인 상태 유지</span>
            </label>
          </div>
          <div className="login-lost">
            <a href="/find-password">비밀번호 찾기</a>
            <a href="/find-id">아이디 찾기</a>
          </div>
        </div>
        <button
          className="submit-btn"
          onClick={() => {
            loginContext.login(keepLogin, 0, loginId, password);
          }}
        >
          로그인
        </button>
      </div>
    </div>
  );
};

export default LoginBox;
