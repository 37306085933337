import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Modal,
  Box,
  Button,
  Pagination,
} from "@mui/material";
import { requestOpen } from "network/Request";

const styles = {
  root: {
    marginTop: "16px",
  },
  paper: {
    padding: "20px",
    //marginBottom: "16px",
    cursor: "pointer",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    //marginBottom: "8px",
  },
  content: {
    fontSize: "16px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    position: "absolute",
    width: "80%",
    maxHeight: "80%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  },
};

const rowsPerPage = 10;

const Notice = () => {
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  const handleOpenModal = (notice) => {
    setSelectedNotice(notice);
  };

  const handleCloseModal = () => {
    setSelectedNotice(null);
  };

  useEffect(() => {
    requestOpen(
      {
        "bk-working": "listing-notice",
        limit_offset: (page - 1) * rowsPerPage,
        limit_range: rowsPerPage,
      },
      (result, response) => {
        if (result) {
          setRows(response["limit_array"]);
          setTotalSize(response["limit_size"]);
        } else {
          alert("데이터를 불러오지 못 했습니다.");
        }
      }
    );
  }, []);

  return (
    <div>
      <div className="commu-container">
        {/* <Typography variant="h4" component="h1" align="center">
          공지사항
        </Typography> */}
        <div className="notice">
          {rows.map((notice, index) => (
            <Paper
              className="notice-wrap"
              key={index}
              style={styles.paper}
              onClick={() => handleOpenModal(notice)}
            >
              <Typography variant="h6" component="h2" style={styles.title}>
                {notice.title}
              </Typography>
              <Typography variant="body1" style={styles.content}>
                {notice.body}
              </Typography>
            </Paper>
          ))}
          <div className="pagination">
            <Pagination
              count={Math.ceil(totalSize / rowsPerPage)}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </div>
        </div>

        <Modal
          className="commu-modal"
          open={selectedNotice !== null}
          onClose={handleCloseModal}
          aria-labelledby="notice-modal-title"
          aria-describedby="notice-modal-description"
          style={styles.modal}
        >
          <Box sx={styles.modalPaper}>
            {selectedNotice && (
              <>
                <Typography
                  variant="h6"
                  component="h2"
                  id="notice-modal-title"
                  style={styles.title}
                >
                  {selectedNotice.title}
                </Typography>
                <Typography
                  variant="body1"
                  id="notice-modal-description"
                  style={styles.content}
                >
                  <div className="notice_img">
                    {selectedNotice.image && <img src={selectedNotice.image} />}
                  </div>
                  <br />
                  {selectedNotice.body}
                </Typography>
                <Button className="notice-btn" onClick={handleCloseModal}>
                  닫기
                </Button>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Notice;
