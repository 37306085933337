import Application from "pages/Class/Main/Application";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "../layout/ClassLayout/MainLayout";
import Community from "../pages/Class/Common/community/Community";
import Course from "../pages/Class/Main/Course";
import Home from "../pages/Class/Common/Home";
import TeacherInfo from "../pages/Class/Common/TeacherInfo";
import ClassInfo from "../pages/Class/Common/ClassInfo";
import Payment from "pages/Class/Main/payment/Payment";
import Success from "pages/Class/Main/payment/Success";
import Fail from "pages/Class/Main/payment/Fail";
import Mypage from "pages/Class/Main/Mypage";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/application" element={<Application />} />
        <Route path="/course" element={<Course />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/success" element={<Success />} />
        <Route path="/fail" element={<Fail />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/community/*" element={<Community />} />
        <Route path="/teacher-info" element={<TeacherInfo />} />
        <Route path="/class-info" element={<ClassInfo />} />
        <Route path="*" element={<Navigate replace to="/course" />} />
      </Route>
    </Routes>
  );
};
export default MainRoutes;
