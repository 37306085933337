import JoinBox from "components/Join/JoinBox";
import React from "react";
import "../../../../assets/main.css";
import "../../../../assets/member.css";
const Join = () => {
  return (
    <div>
      <JoinBox />
    </div>
  );
};

export default Join;
