// import { useContext } from "react"
import { Route, Routes } from "react-router-dom";
import "../index.css";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import { test } from "controller/TestController";
import { useContext, useState } from "react";
import { LoginContext } from "controller/provider/LoginProvider";

export default function ThemeRoutes() {
  const loginContext = useContext(LoginContext);

  useState(() => test(loginContext), []);

  return loginContext.isInitialized ? (
    loginContext.isLoggedIn ? (
      <MainRoutes />
    ) : (
      <LoginRoutes />
    )
  ) : (
    <Routes></Routes>
  );
}
