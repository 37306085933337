import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import FAQ from "./FAQ";
import QnA from "./QnA";
import Notice from "./Notice";
import "../../../../assets/main.css";

const Community = () => {
  return (
    <div className="container">
      <div className="con-tit align-center">
        <h1>커뮤니티</h1>
        <div className="link_box">
          <Link to="notice" className="link_item">
            공지사항
          </Link>
          <Link to="faq" className="link_item">
            FAQ
          </Link>
          <Link to="qna" className="link_item">
            Q&A
          </Link>
        </div>
      </div>
      <Routes>
        <Route path="/faq" element={<FAQ />} />
        <Route path="/qna" element={<QnA />} />
        <Route path="/notice" element={<Notice />} />
      </Routes>
    </div>
  );
};

export default Community;
