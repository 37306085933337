import React, { useContext, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "../../../assets/main.css";
import "../../../assets/sub.css";
import "../../../assets/calendar.css";
import {
  listingCompanyHolidays,
  listingClassJoinFilter,
  listingClassSchedule,
  listingReservableHours,
  listingClass,
  insertClassJoin,
  editClassJoin,
  deleteClassJoin,
  listingNationalHolidays,
} from "controller/ClassJoinController";
import { koreanHours, koreanMillisec, ymd, ymdToDate } from "tools/dateutil";
import {
  listingCredits,
  listingCreditsMonthly,
} from "controller/CreditController";
import { useNavigate } from "react-router-dom";
import { request } from "network/Request";
import { CourseContext } from "controller/provider/CourseProvider";
import { Grid } from "@mui/material";

const todayLastTime = 16;

const Application = () => {
  const [today] = useState(new Date());
  const [date, setDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [regularHolidayFlag, setRegularHolidayFlag] = useState(0);
  const [temporaryHolidays, setTemporaryHolidays] = useState([]);
  const [fullReserveDays, setFullReserveDays] = useState([]);
  const [classJoinArray, setClassJoinArray] = useState([]);
  const [classScheduleArray, setClassScheduleArray] = useState([]);
  const [reservableHours, setReservableHours] = useState({});
  const [myCreditArray, setMyCreditArray] = useState([]);
  const [monthlyCredit, setMonthlyCredit] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [currentScheduleId, setCurrentScheduleId] = useState(0);
  const [currentClassJoin, setCurrentClassJoin] = useState(null);
  const [currentClassId, setCurrentClassId] = useState(0);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [levelCourseArray, setLevelCourseArray] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [koreanSpeaking, setKoreanSpeaking] = useState();
  const [nationalHolidays, setNationalHolidays] = useState([]);
  const [showCredit, setShowCredit] = useState(false);

  const toggleCredit = () => {
    setShowCredit(!showCredit);
  };

  const { addCourse, removeCourse, editCourse } = useContext(CourseContext);

  useEffect(() => {
    listingClassSchedule().then(({ result, response }) => {
      if (result) {
        setClassScheduleArray(response["limit_array"]);
      } else {
        console.log("수업 스케줄을 불러오지 못했습니다.");
      }
    });

    listingClass().then(({ result, response }) => {
      if (result) {
        setLevelCourseArray(
          response["limit_array"].reduce((newObj, obj) => {
            if (!newObj[obj.level]) newObj[obj.level] = [];
            newObj[obj.level].push({
              course: obj.course,
              class_id: obj["class_id"],
            });
            return newObj;
          }, {})
        );
      } else {
        console.log("강의 정보를 불러오지 못했습니다.");
      }
    });

    request({ "bk-working": "detail-user" }, (result, response) => {
      if (result) {
        let detail = response["user_detail"];
        setKoreanSpeaking(detail["korean_speaking"]);
      } else {
        console.log("사용자 정보를 불러오지 못했습니다.");
      }
    });
  }, []);

  useEffect(() => {
    if (!currentDate || !koreanSpeaking) {
      return;
    }
    listingCredits(currentDate.getTime()).then(({ result, response }) => {
      if (result) {
        setMyCreditArray(
          response["limit_array"].filter(
            (item) => item["total_limit"] > item["total_usage"]
          )
        );
      } else {
        console.log("결제정보를 불러오지 못했습니다.");
      }
    });
    listingReservableHours(ymd(currentDate), koreanSpeaking).then(
      ({ result, response }) => {
        if (result) {
          setReservableHours(response["available_json"]);
        } else {
          console.log("예약가능 시간을 불러오지 못했습니다.");
        }
      }
    );
  }, [currentDate, koreanSpeaking]);

  useEffect(() => {
    if (!koreanSpeaking) {
      return;
    }
    let nextMonth = new Date(date);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    listingCompanyHolidays(
      date.getTime() / 60 / 1000,
      nextMonth.getTime() / 60 / 1000,
      date.getFullYear() * 10000 + (date.getMonth() + 1) * 100,
      koreanSpeaking
    ).then(({ result, response }) => {
      if (result) {
        setRegularHolidayFlag(response["holiday_flag"]);
        setTemporaryHolidays(
          response["company_holiday_epoch_min"].map((item) => item * 60 * 1000)
        );
        setFullReserveDays(
          response["teacher_holiday_array"].map((item) => {
            return koreanMillisec(ymdToDate(item));
          })
        );
      } else {
        console.log("휴일 정보를 확인할 수 없습니다.");
      }
    });

    listingNationalHolidays(ymd(date), ymd(nextMonth)).then(
      ({ result, response }) => {
        if (result) {
          setNationalHolidays(response["national_holiday_array"]);
        }
      }
    );

    listingClassJoinFilter(ymd(date), ymd(nextMonth)).then(
      ({ result, response }) => {
        if (result) {
          let filterCancel = response["my_class_array"].filter((item) => {
            return !(item["class_report"] && item["class_report"][0] === "C");
          });
          console.log(filterCancel);
          setClassJoinArray(filterCancel);
        } else {
          alert("수업 현황을 불러올 수 없습니다.");
        }
      }
    );

    listingCreditsMonthly(
      date.getTime(),
      new Date(date.getFullYear(), date.getMonth() + 1, -1).getTime()
    ).then(({ result, response }) => {
      if (result) {
        setMonthlyCredit(response["credit_array"]);
      }
    });
  }, [date, refreshKey, koreanSpeaking]);

  const onChangeStartDate = (d) => {
    setDate(d.activeStartDate);
  };

  const onDayClick = (d) => {
    if (
      (1 << d.getDay()) & regularHolidayFlag ||
      temporaryHolidays.includes(koreanMillisec(d))
    ) {
      if (d.getMonth() !== date.getMonth()) {
        return;
      }
    }
    if (ymd(d) < ymd(new Date())) {
      return;
    } else if (ymd(d) === ymd(new Date())) {
      if (koreanHours(new Date()) >= todayLastTime) return;
    }

    setCurrentClassJoin(null);
    setCurrentClassId(0);
    setCurrentScheduleId();
    setMyCreditArray([]);
    setReservableHours({});
    setOpenNewModal(true);
    setCurrentDate(d);
  };

  const onItemChange = (item, index) => {
    setCurrentClassJoin(item);
    setCurrentClassId(item["class_id"]);
    setCurrentScheduleId(item["class_schedule_id"]);
    setCheckedIndex(index);
  };

  const onItemClick = (e, item, date) => {
    onItemChange(item);
    setMyCreditArray([]);
    setReservableHours({});
    setOpenNewModal(true);
    setCurrentDate(date);
    e.stopPropagation();
  };

  const onSubmit = () => {
    if ((currentClassId ?? 0) <= 0) {
      alert("수업을 선택해주세요.");
      return;
    }
    if ((currentScheduleId ?? 0) <= 0) {
      alert("일정을 선택해주세요.");
      return;
    }
    if (!currentDate) {
      alert("오류-날짜가 선택되지 않았습니다.");
    }
    const creditId =
      myCreditArray.find((item) => item["credit_type"] === "G")?.credit_id ??
      myCreditArray.find((item) => item["credit_type"] === "P")?.credit_id ??
      myCreditArray.find((item) => item["credit_type"] === "C")?.credit_id;
    // insertClassJoin()
    insertClassJoin(
      currentClassId,
      ymd(currentDate),
      currentScheduleId,
      creditId,
      koreanSpeaking
    ).then(({ result, response }) => {
      alert(result ? "예약되었습니다." : "예약실패");
      if (result) {
        setOpenNewModal(false);
        setRefreshKey(refreshKey + 1);
        addCourse(response);
      }
    });
  };

  const onRemove = () => {
    if (currentClassJoin["ymd"] + "" === ymd(new Date()) + "") {
      if (koreanHours(new Date()) >= todayLastTime) {
        alert("당일 16시 이후는 예약 변경이 불가능합니다.");
        return;
      }
    }
    if (window.confirm("삭제하시겠습니까?")) {
      deleteClassJoin(
        currentClassJoin["class_join_id"],
        currentClassJoin["credit_id"]
      ).then(({ result }) => {
        if (result) {
          removeCourse(currentClassJoin["class_join_id"]);
          setClassJoinArray((prevState) =>
            prevState.filter(
              (item) =>
                item["class_join_id"] !== currentClassJoin["class_join_id"]
            )
          );
          alert(result ? "삭제되었습니다." : "변경실패");
          setOpenNewModal(false);
          setRefreshKey(refreshKey + 1);
        }
      });
    }
  };

  const onEdit = () => {
    if (currentClassJoin["ymd"] + "" === ymd(new Date()) + "") {
      if (koreanHours(new Date()) >= todayLastTime) {
        alert("당일 16시 이후는 예약 변경이 불가능합니다.");
        return;
      }
    }
    if (window.confirm("변경하시겠습니까?")) {
      editClassJoin(
        currentClassJoin["class_join_id"],
        currentClassJoin["ymd"],
        currentClassId,
        currentClassJoin["class_schedule_id"] !== currentScheduleId
          ? currentScheduleId
          : 0
      ).then(({ result, response }) => {
        if (result) {
          setOpenNewModal(false);
          editCourse(response);
          setRefreshKey(refreshKey + 1);
        }
        alert(result ? "변경되었습니다" : "변경실패");
      });
    }
  };
  const navigate = useNavigate();

  const onVideo = () => {
    request(
      {
        "bk-working": "user-room-api",
        operation: "video",
        class_join_id: currentClassJoin["class_join_id"],
      },
      (result, response) => {
        if (result) {
          let url = response["url"];
          window.open(url);
        }
      }
    );
  };
  const buttons = document.querySelectorAll(".react-calendar__tile");
  buttons.forEach((button) => {
    if (button.querySelector(".past_date")) {
      button.classList.add("event_none");
    }
  });

  const myButtons = document.querySelectorAll(".event_none");
  myButtons.forEach((button) => {
    button.style.pointerEvents = "none";
  });
  const moveToPay = () => {
    navigate("/payment");
  };
  const newModal = () => {
    return (
      <div className="pure-modal-overlay">
        <label htmlFor="ap-pop" className="o-close"></label>
        <div className="pure-modal-wrap a-center">
          <label htmlFor="ap-pop" className="close">
            &#10006;
          </label>
          <div className="ap-pop-wrap">
            <div className="ap-date">
              <h1>
                {currentDate?.getFullYear()}년 {currentDate?.getMonth() + 1}월
                {currentDate?.getDate()}일
              </h1>
            </div>
            <div className="ap-pop-con flex">
              <div className="ap-con">
                <h2 className="ap-con-tit ap-con-tit1">수강시간</h2>
                <div className="ap-time">
                  {classScheduleArray.map((item, index) => {
                    const currentYmd = currentDate && ymd(currentDate);
                    const reservable =
                      (reservableHours[item["class_schedule_id"]] ?? 0) > 0 &&
                      !classJoinArray.find((joinItem) => {
                        const result =
                          parseInt(joinItem["ymd"]) === parseInt(currentYmd) &&
                          joinItem["class_schedule_id"] ===
                            item["class_schedule_id"];
                        return result;
                      });
                    return (
                      <button
                        style={{
                          backgroundColor:
                            item["class_schedule_id"] === currentScheduleId
                              ? "#d3e1ff"
                              : reservable
                              ? "white"
                              : "#e1e1e1",
                        }}
                        key={index}
                        onClick={() =>
                          myCreditArray.length === 0
                            ? (alert(
                                "사용 가능한 크레딧이 없습니다. 결제창으로 이동합니다."
                              ),
                              navigate("/payment"))
                            : reservable
                            ? setCurrentScheduleId(item["class_schedule_id"])
                            : null
                        }
                      >
                        {`${item["start_hhmm"].substring(0, 2)}:${item[
                          "start_hhmm"
                        ].substring(2, 4)}`}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="ap-con">
                <h2 className="ap-con-tit ap-con-tit2">수강레벨</h2>
                <div className="ap-course">
                  <select
                    value={currentClassId}
                    name="course"
                    onChange={(e) => setCurrentClassId(e.target.value)}
                  >
                    <option value={0} hidden>
                      레벨선택
                    </option>
                    {Object.keys(levelCourseArray).map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={levelCourseArray[item][0]["class_id"]}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* <div className="ap-con">
                <h2 className="ap-con-tit ap-con-tit3">수강진도</h2>
                <div className="ap-course-map">
                  <select
                    value={currentClassId}
                    name="course-map"
                    onChange={(e) => setCurrentClassId(e.target.value)}
                  >
                    <option value={0} hidden>
                      진도선택
                    </option>
                    {currentLevel &&
                      levelCourseArray[currentLevel].map((item, index) => (
                        <option key={index} value={item["class_id"]}>
                          {item["course"]}
                        </option>
                      ))}
                  </select>
                </div>
              </div> */}
            </div>
            <div className="ap-btn-wrap">
              <button className="ap-btn" onClick={() => onSubmit()}>
                수강신청
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const editModal = () => {
    return (
      <div className="pure-modal-overlay">
        <label htmlFor="ap-pop" className="o-close"></label>
        <div className="pure-modal-wrap a-center">
          <label htmlFor="ap-pop" className="close">
            &#10006;
          </label>
          <div className="ap-pop-wrap">
            <div className="ap-date">
              <h1>
                {currentDate?.getFullYear()}년 {currentDate?.getMonth() + 1}월
                {currentDate?.getDate()}일
              </h1>
            </div>
            <div className="ap-list-con">
              <h2 className="ap-con-tit ap-con-tit4">신청 목록</h2>
              {classJoinArray
                .filter(
                  (element) =>
                    parseInt(element["ymd"]) === currentClassJoin["ymd"]
                )
                .map((item, index) => (
                  <div
                    onClick={() => onItemChange(item)}
                    key={index}
                    className="list"
                  >
                    <input
                      type="radio"
                      id={`item${item["class_join_id"]}`}
                      name="classJoinRadio"
                      checked={
                        checkedIndex === index ||
                        currentClassJoin["class_schedule_id"] ===
                          item["class_schedule_id"]
                      }
                      onChange={() => {}}
                    />
                    <label htmlFor={`item${item["class_join_id"]}`}></label>
                    <div className="ap-list-in">
                      <span>
                        수강시간:
                        <strong>
                          {`${item["start_hhmm"].substring(0, 2)}:${item[
                            "start_hhmm"
                          ].substring(2, 4)}`}
                        </strong>
                      </span>
                      <span>
                        수강레벨: <strong>{item["level"]}</strong>
                      </span>
                      <span>
                        수강강사:
                        <strong>
                          {item["teacher_name"]}
                        </strong>
                      </span>
                      {/* <span>
          수강진도: <strong> {item["course"]}</strong>
        </span> */}

                      <strong>
                        {item["class_report"] && (
                          <span>
                            {item["class_report"].substr(0, 1) === "P"
                              ? "출석"
                              : item["class_report"].substr(0, 1) === "L"
                              ? "지각"
                              : item["class_report"].substr(0, 1) === "A"
                              ? "결석"
                              : ""}
                          </span>
                        )}
                      </strong>
                    </div>
                  </div>
                ))}
            </div>
            {currentClassJoin["class_report"] ? (
              <div className="ap-pop-con flex">
                <div className="ap-con">
                  <h2 className="ap-con-tit ap-con-tit1">접속시간</h2>
                  <div className="ap-time rep-time">
                    {currentClassJoin["user_enter_time"] ? (
                      <h3>
                        {new Date(
                          currentClassJoin["user_enter_time"]
                        ).toLocaleString("ko-KR", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </h3>
                    ) : (
                      <h3>접속하지 않았습니다.</h3>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="ap-con">
                  <h2 className="ap-con-tit ap-con-tit2">점수</h2>
                  <br />
                  {currentClassJoin["class_report"] ? (
                    <div className="rep-point">
                      <table>
                        <colgroup>
                          <col style={{ width: "50%" }}></col>
                          <col style={{ width: "50%" }}></col>
                        </colgroup>
                        <tr>
                          <td>Speaking</td>
                          <td>
                            {currentClassJoin["class_report"].substr(1, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(1, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td>Pronunciation</td>
                          <td>
                            {currentClassJoin["class_report"].substr(2, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(2, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td>Grammar</td>
                          <td>
                            {currentClassJoin["class_report"].substr(3, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(3, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td>Vocabulary</td>
                          <td>
                            {currentClassJoin["class_report"].substr(4, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(4, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td>Listening</td>
                          <td>
                            {currentClassJoin["class_report"].substr(5, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(5, 1)}
                          </td>
                        </tr>
                        <tr>
                          <td>Overall</td>
                          <td>
                            {currentClassJoin["class_report"].substr(6, 1) ===
                            "A"
                              ? "10"
                              : currentClassJoin["class_report"].substr(6, 1)}
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    <span>점수가 없습니다.</span>
                  )}
                </div>
                <div className="ap-con">
                  <h2 className="ap-con-tit ap-con-tit3 comment-box">코멘트</h2>
                  <div
                    className="ap-course-map"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <span className="rep-fdb">
                      {currentClassJoin["feedback"]}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ap-pop-con flex">
                <div className="ap-con">
                  <h2 className="ap-con-tit ap-con-tit1">수강시간</h2>
                  <div className="ap-time">
                    {classScheduleArray.map((item, index) => {
                      const currentYmd = currentDate && ymd(currentDate);
                      const reservable =
                        (reservableHours[item["class_schedule_id"]] ?? 0) > 0 &&
                        !classJoinArray
                          .filter(
                            (item) =>
                              item["class_join_id"] !==
                              currentClassJoin["class_join_id"]
                          )
                          .find((joinItem) => {
                            const result =
                              parseInt(joinItem["ymd"]) ===
                                parseInt(currentYmd) &&
                              joinItem["class_schedule_id"] ===
                                item["class_schedule_id"];
                            return result;
                          });
                      return (
                        <button
                          className="ap-time-btn"
                          style={{
                            backgroundColor:
                              item["class_schedule_id"] === currentScheduleId
                                ? "#d3e1ff"
                                : reservable
                                ? "white"
                                : "#e1e1e1",
                          }}
                          key={index}
                          onClick={() =>
                            reservable
                              ? setCurrentScheduleId(item["class_schedule_id"])
                              : null
                          }
                        >{`${item["start_hhmm"].substring(0, 2)}:${item[
                          "start_hhmm"
                        ].substring(2, 4)}`}</button>
                      );
                    })}
                  </div>
                </div>
                <div></div>
                <div className="ap-con">
                  <h2 className="ap-con-tit ap-con-tit2">수강레벨</h2>
                  <div className="ap-course">
                    <select
                      className="ap-select"
                      value={currentClassId}
                      name="course"
                      onChange={(e) => setCurrentClassId(e.target.value)}
                    >
                      <option value={0} hidden>
                        레벨선택
                      </option>
                      {Object.keys(levelCourseArray).map((item, index) => (
                        <option
                          key={index}
                          value={levelCourseArray[item][0].class_id}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className="ap-con">
                  <h2 className="ap-con-tit  ap-con-tit3">수강진도</h2>
                  <div className="ap-course-map">
                    <select
                      className="ap-select"
                      value={currentClassId}
                      name="course-map"
                      onChange={(e) => setCurrentClassId(e.target.value)}
                    >
                      <option value={0} hidden>
                        진도선택
                      </option>
                      {currentLevel &&
                        levelCourseArray[currentLevel].map((item, index) => (
                          <option key={index} value={item["class_id"]}>
                            {item["course"]}
                          </option>
                        ))}
                    </select>
                  </div>
                </div> */}
              </div>
            )}

            {currentClassJoin["class_report"] ? (
              <button className="ap-btn ap-delete" onClick={() => onVideo()}>
                영상보기
              </button>
            ) : (
              <div className="flex ap-btn-wrap">
                <button
                  className="ap-btn ap-delete"
                  onClick={() => onRemove()}
                  disabled={
                    parseInt(currentClassJoin["ymd"]) * 10000 +
                      parseInt(currentClassJoin["start_hhmm"]) <
                    parseInt(ymd(new Date())) * 10000 +
                      new Date().getHours() * 100 +
                      new Date().getMinutes()
                  }
                >
                  삭제
                </button>
                <button
                  className="ap-btn ap-edit"
                  onClick={() => onEdit()}
                  disabled={
                    parseInt(currentClassJoin["ymd"]) * 10000 +
                      parseInt(currentClassJoin["start_hhmm"]) <
                    parseInt(ymd(new Date())) * 10000 +
                      new Date().getHours() * 100 +
                      new Date().getMinutes()
                  }
                >
                  수정
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const isReservable = (date) => {
    let targetDate = new Date(date);

    if (fullReserveDays.includes(koreanMillisec(date))) {
      return false;
    }
    if (
      ymd(date) < ymd(new Date()) ||
      targetDate.getTime() >
        new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate()
        ).getTime()
    ) {
      return false;
    } else if (ymd(date) === ymd(new Date())) {
      if (koreanHours(new Date()) >= todayLastTime) {
        return false;
      }
    }
    // console.log(targetDate);
    let startDate = new Date(targetDate);
    startDate.setHours(23);

    return (
      monthlyCredit.filter((item) => {
        if (
          item["start_millisec"] <= koreanMillisec(startDate) &&
          item["end_millisec"] > koreanMillisec(targetDate)
        ) {
          return true;
        }
      }).length > 0
    );
  };

  return (
    <div className="application">
      {/* modal */}
      <div className="pure-modal" id="application-pop">
        <input
          id="ap-pop"
          className="checkbox"
          type="checkbox"
          checked={openNewModal}
          onChange={(e) => setOpenNewModal(e.target.checked)}
        />
        {currentClassJoin ? editModal() : newModal()}
      </div>
      {/* modal */}

      <div className="container">
        <div className="con-tit">
          <h1>수강신청</h1>
        </div>


        <div className="cal-info">
          <p>
            <span>러닝타임</span> 30분
          </p>
          <p>
            <span>기간</span> 1개월 후 까지 신청가능
          </p>
          <p>
            <span>방법</span> 수업을 원하는 날짜를 클릭해주세요!
          </p>

          <button className="credit-button" onClick={toggleCredit}>
            {(showCredit && <span>잔여횟수 닫기</span>) || (
              <span>잔여횟수 보기</span>
            )}
          </button>
        </div>

        <div className="cal-top">
          <div className="cal-top-wrap">
            {showCredit ? (
              Object.values(
                monthlyCredit
                  .sort((a, b) => {
                    if (a["credit_type"] === "G") return -1;
                    if (b["credit_type"] === "G") return 1;
                    return 0;
                  })
                  .reduce((result, item, index) => {
                    const remainingCount =
                      item["total_limit"] - item["total_usage"];
                    const itemContent = (
                      <div className="cal-data" key={index}>
                        {/* 잔여 횟수 : */}
                        {item["credit_type"] === "P"
                          ? " 개인 : "
                          : item["credit_type"] === "G"
                          ? " 그룹 : "
                          : item["credit_type"] === "C"
                          ? " 보강 : "
                          : " 오류 : "}
                        <span className="colorBlue"> {remainingCount}</span>/
                        <span> {item["total_limit"]} </span>
                        <span className="total-date">
                          (
                          {new Date(
                            item["start_millisec"]
                          ).toLocaleDateString()}
                          ~{new Date(item["end_millisec"]).toLocaleDateString()}
                          )
                        </span>
                      </div>
                    );

                    if (!result[item["credit_type"]]) {
                      result[item["credit_type"]] = [itemContent];
                    } else {
                      result[item["credit_type"]].push(itemContent);
                    }
                    return result;
                  }, {})
              ).length > 0 &&
              monthlyCredit.some(
                (item) => item["total_limit"] - item["total_usage"] > 0
              ) ? (
                <div className="credit-container">
                  <Grid container spacing={1} className="cal-credit-list">
                    {Object.values(
                      monthlyCredit
                        .sort((a, b) => {
                          if (a["credit_type"] === "G") return -1;
                          if (b["credit_type"] === "G") return 1;
                          return 0;
                        })
                        .reduce((result, item, index) => {
                          const remainingCount =
                            item["total_limit"] - item["total_usage"];
                          const itemContent = (
                            <div className="cal-data" key={index}>
                              {/* 잔여 횟수 : */}
                              {item["credit_type"] === "P"
                                ? " 개인 : "
                                : item["credit_type"] === "G"
                                ? " 그룹 : "
                                : item["credit_type"] === "C"
                                ? " 보강 : "
                                : " 오류 : "}
                              <span className="colorBlue">
                                {remainingCount}
                              </span>
                              /<span> {item["total_limit"]} </span>
                              <span className="total-date">
                                (
                                {new Date(
                                  item["start_millisec"]
                                ).toLocaleDateString()}
                                ~
                                {new Date(
                                  item["end_millisec"]
                                ).toLocaleDateString()}
                                )
                              </span>
                            </div>
                          );

                          if (!result[item["credit_type"]]) {
                            result[item["credit_type"]] = [itemContent];
                          } else {
                            result[item["credit_type"]].push(itemContent);
                          }
                          return result;
                        }, {})
                    ).map((creditGroup, index) => (
                      <Grid item xs={4} key={index}>
                        {creditGroup.slice(0, 2)}
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : (
                <span className="no-credit">
                  잔여 크레딧이 없습니다.
                  <button onClick={moveToPay}>결제하기</button>
                </span>
              )
            ) : null}
          </div>

          <div className="color-wrap">
            <div className="center">
              수강신청 및 취소는 당일 16시 이후로는 불가합니다.
              {/* <br /> You cannot register or cancel classes after 16:00 on the
              same day. */}
            </div>
            <div className="color-info">
              <div className="day-info">휴무일</div>
              <div className="day-info">공휴일</div>
              <div className="day-info">수강신청 불가</div>
              <div className="day-info">수강신청 가능</div>
            </div>
          </div>

          <div className="calendar">
            <Calendar
              onClickDay={onDayClick}
              calendarType="US"
              locale="en-US"
              firstDayOfWeek={0}
              formatShortWeekday={(locale, date) => {
                return new Intl.DateTimeFormat(locale, { weekday: "short" })
                  .format(date)
                  .toUpperCase();
              }}
              formatMonthYear={(locale, date) => {
                const year = new Intl.DateTimeFormat(locale, {
                  year: "numeric",
                }).format(date);
                const month = new Intl.DateTimeFormat(locale, {
                  month: "short",
                })
                  .format(date)
                  .toUpperCase();
                return `${year} / ${new Date(date).getMonth() + 1} (${month})`;
              }}
              onActiveStartDateChange={onChangeStartDate}
              tileContent={({ date }) => {
                const dateYmd = ymd(date);
                const todayClassArray = classJoinArray.filter(
                  (element) => parseInt(element["ymd"]) === parseInt(dateYmd)
                );
                const isToday = ymd(date) === ymd(new Date());

                const startHhmm = todayClassArray[0]?.start_hhmm;
                const isPastDate =
                  startHhmm &&
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    parseInt(startHhmm.substring(0, 2)),
                    parseInt(startHhmm.substring(2))
                  ).setMinutes(parseInt(startHhmm.substring(2)) + 30) <=
                    new Date();
                if (todayClassArray.length === 1) {
                  return (
                    <div
                      onClick={() => onDayClick(date)}
                      className={` ${isPastDate ? "past_date" : ""}`}
                    >
                      <div className="react-calendar_drenglish_event_container">
                        <div
                          onClick={(e) =>
                            onItemClick(e, todayClassArray[0], date)
                          }
                          className={`react-calendar_drenglish_event ${
                            todayClassArray[0]["class_join_state"] === "END"
                              ? "end_event"
                              : ""
                          }
                           ${isPastDate ? "end_event" : ""}`}
                          key={0}
                        >
                          {`${startHhmm?.substring(
                            0,
                            2
                          )}:${startHhmm?.substring(2, 4)} ${
                            todayClassArray[0]["level"]
                          }`}
                        </div>
                      </div>
                      {isToday && <div className="calendar-today"></div>}
                    </div>
                  );
                } else if (todayClassArray.length > 1) {
                  return (
                    <div
                      onClick={() => onDayClick(date)}
                      className={` ${isPastDate ? "past_date" : ""}`}
                    >
                      <div className="react-calendar_drenglish_event_container ">
                        <div
                          onClick={(e) =>
                            onItemClick(e, todayClassArray[0], date)
                          }
                          className={`react-calendar_drenglish_event ${
                            isPastDate ? "end_event" : ""
                          }`}
                          key={0}
                        >
                          {`${startHhmm?.substring(
                            0,
                            2
                          )}:${startHhmm?.substring(2, 4)} ${
                            todayClassArray[0]["level"]
                          }`}
                          <span className="plus-badge">
                            +{todayClassArray.length - 1}
                          </span>
                        </div>
                      </div>
                      {isToday && <div className="calendar-today"></div>}
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => onDayClick(date)}
                      className={` ${isPastDate ? "past_date" : ""}`}
                    >
                      {isPastDate && <div className="end_event"></div>}
                      {isToday && <div className="calendar-today"></div>}
                    </div>
                  );
                }
              }}
              tileClassName={({ date, view }) => {
                if (
                  nationalHolidays.filter(
                    (item) => ymd(date) + "" === item["ymd"] + ""
                  ).length > 0
                ) {
                  return "react-calendar_national_holiday";
                } else if (
                  (1 << date.getDay()) & regularHolidayFlag ||
                  temporaryHolidays.includes(koreanMillisec(date))
                ) {
                  return "react-calendar_drenglish_disabled";
                } else if (!isReservable(date)) {
                  return "react-calendar-no-credits";
                } else {
                  return "";
                }
              }}
              tileDisabled={({ activeStartDate, date, view }) => {
                const disabledDates = document.querySelectorAll(
                  ".react-calendar_drenglish_disabled"
                );
                for (let i = 0; i < disabledDates.length; i++) {
                  if (
                    disabledDates[i].getAttribute("aria-label") ===
                    date.toDateString()
                  ) {
                    return true;
                  }
                }
                return false;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
