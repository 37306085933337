import React from "react";

import "../../../assets/main.css";
import "../../../assets/sub.css";
import teacher1 from "../../../assets/images/teacher1.jpg";
import teacher2 from "../../../assets/images/teacher2.jpg";
import {Link} from "react-router-dom";

const ClassInfo = () => {
    return (
        <div className="teacher-info">
            <div className="sub-top">
                <div className="sub-bg class">
                    <div className="sub-top-txt">
                        Class
                        <br/>
                        introduction
                        <span className="block">
                            1:1 English classes
                            <br/>a variety of Native teachers
                        </span>
                    </div>
                </div>
            </div>

            <div className="sub-con">
                <div className="logo-bg korean">
                    <div className="container">
                        <span>Dr. English</span>는 원어민 강사진이
                        <br/>
                        기초부터 고급까지 여러분의 영어 학습을 집중적으로 관리합니다.
                        <br/>
                        최적화된 시스템으로 여러 선생님들과 일대일 화상영어 수업이 가능합니다.
                        <br/>
                        <span>Dr. English</span>에서는 여러분들의 목표와 꿈을 달성하도록 최선을 다하고 있습니다.
                    </div>
                </div>

                <div className="solution-wrap">
                    <div className="container">
                        <ol className="solution-chart">
                            <li>
                                <div className="con-tit">
                                    <h1>제공솔루션</h1>
                                </div>
                                <ol>
                                    <li>
                                        <div>
                                            <h2>General English</h2>
                                        </div>
                                        <p>
                                            <span>일상 대화</span>
                                            <span>뉴스 & 정보</span>
                                            <span>실용영어</span>
                                        </p>
                                    </li>
                                    <li>
                                        <div>
                                            <h2>Business English</h2>
                                        </div>
                                        <p>
                                            <span>프리젠테이션</span>
                                            <span>미팅용어</span>
                                            <span>이메일작성</span>
                                        </p>
                                    </li>
                                    <li>
                                        <div>
                                            <h2>Special Program</h2>
                                        </div>
                                        <p>
                                            <span>여행 영어</span>
                                            <span>워킹 홀리데이</span>
                                            <span>의료 영어</span>
                                            <span>전문 영어</span>
                                        </p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="price">
                    <div className="container">
                        <div className="con-tit">
                            <h1>수업 가격</h1>
                        </div>

                        <div className="price-con">

                            <table className="price-table">
                                <colgroup>
                                    <col width="10%"/>
                                    <col width="30%"/>
                                    <col width="30%"/>
                                    <col width="30%"/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <b>8
                                            </b>Classes
                                        </td>
                                        <td>
                                            <b>10
                                            </b>Classes
                                        </td>
                                        <td>
                                            <b>12
                                            </b>Classes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            1개월
                                        </td>
                                        <td className="price-td2">
                                            240,000
                                        </td>
                                        <td className="price-td2">
                                            260,000
                                        </td>
                                        <td className="price-td2">
                                            270,000
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            3개월<span className="discount bg-blue">- 5%</span>
                                        </td>
                                        <td className="price-td">
                                            <span>720,000</span>684,000
                                        </td>
                                        <td className="price-td">
                                            <span>780,000</span>741,000
                                        </td>
                                        <td className="price-td">
                                            <span>810,000</span>769,000
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            6개월<span className="discount bg-blue">- 7%</span>
                                        </td>
                                        <td className="price-td">
                                            <span>1,440,000</span>1,339,000
                                        </td>
                                        <td className="price-td">
                                            <span>1,560,000</span>1,450,000
                                        </td>
                                        <td className="price-td">
                                            <span>1,620,000</span>1,506,000
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            12개월<span className="discount bg-blue">- 10%</span>
                                        </td>
                                        <td className="price-td">
                                            <span>2,880,000</span>2,592,000
                                        </td>
                                        <td className="price-td">
                                            <span>3,120,000</span>2,808,000
                                        </td>
                                        <td className="price-td">
                                            <span>3,240,000</span>2,916,000
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Link to="/application">수강하기</Link>
                            <p className="b2b-txt">B2B는 우측 버튼으로 별도 문의바랍니다</p>
                        </div>
                    </div>
                </div>
                {/* price */}
            </div>
            {/*sub_con*/}
        </div>
    );
};

export default ClassInfo;
