import moment from "moment-timezone";

export const ymd = (date) => {
  return moment(date).tz('Asia/Seoul').format('YYYYMMDD');
};

export const ymdToDate = (ymd) => {
  const ymdString = ymd + '';
  return new Date(ymdString.substring(0, 4), ymdString.substring(4, 6) - 1, ymdString.substring(6, 8));
};

export const dateDiff = (date1, date2) => {
  console.log(date1, date2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  const diffTime = Math.abs(date1 - date2);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// utc+3 의 00시 00분인 경우 utc+9 의 00시 00분으로 변경
export const koreanMillisec = (date) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  return date.getTime() + timezoneOffset + 540 * 60000;
}

export const koreanHours = (date) => {
  let d = new Date(koreanMillisec(date));
  return d.getHours();
}

export const koreanMinutes = (date) => {
  let d = new Date(koreanMillisec(date));
  return d.getMinutes();
}