import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/main.css";

const LoginGnb = ({ isSticky, blackTheme, pathname }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const menuBtn = document.querySelector(".menu-btn ");
    const aElements = document.querySelectorAll(".nav-links li");
    const heaDer = document.querySelector(".header");

    menuBtn.addEventListener("click", () => {
      heaDer.classList.add("left-100");
    });

    aElements.forEach((a) => {
      a.addEventListener("click", () => {
        heaDer.classList.remove("left-100");
      });
    });
  }, [location]);

  return (
    <div className="nav-wrap">
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn">
        <i></i>
      </label>

      <ul className={blackTheme ? "nav nav-links nav_test" : "nav nav-links"}>
        <li>
          <Link
            className={location.pathname.includes("class-info") ? "active" : ""}
            to="/class-info"
            style={{ color: blackTheme || isSticky ? "black" : "white" }}
          >
            수업소개
          </Link>
        </li>
        <li>
          <Link
            to="/teacher-info"
            className={
              location.pathname.includes("teacher-info") ? "active" : ""
            }
            style={{ color: blackTheme || isSticky ? "black" : "white" }}
          >
            강사소개
          </Link>
        </li>
        <li>
          <Link
            to="/community/notice"
            className={location.pathname.includes("community") ? "active" : ""}
            style={{ color: blackTheme || isSticky ? "black" : "white" }}
          >
            커뮤니티
          </Link>
        </li>
        <li>
          <Link
            to="/application"
            className={
              location.pathname.includes("application") ? "active" : ""
            }
            style={{ color: blackTheme || isSticky ? "black" : "white" }}
          >
            수강 예약
          </Link>
        </li>
        <li>
          <Link
            to="/course"
            className={location.pathname.includes("course") ? "active" : ""}
            style={{ color: blackTheme || isSticky ? "black" : "white" }}
          >
            학습 현황
          </Link>
        </li>
      </ul>

      <div className="nav-member">
        <Link
          className={location.pathname.includes("join") ? "active" : ""}
          style={{ color: blackTheme || isSticky ? "black" : "white" }}
          to="/join"
        >
          회원가입
        </Link>
        <Link
          className={location.pathname.includes("login") ? "active" : ""}
          style={{ color: blackTheme || isSticky ? "black" : "white" }}
          to="/login"
        >
          로그인
        </Link>
      </div>
    </div>
  );
};

export default LoginGnb;
