import { request } from "network/Request";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "../../../../assets/main.css";
import "../../../../assets/sub.css";
import "../../../../assets/member.css";
// import { LoginContext } from "controller/provider/LoginProvider";

const UserInfo = () => {
  const [userName, setUserName] = useState("");
  const [engname, setEngname] = useState("");
  const [birth, setBirth] = useState("");
  const [loginId, setLoginId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [koreanSpeaking, setKoreanSpeaking] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    //사용자 정보 수정 api연동
  };
  useEffect(() => {
    request({ "bk-working": "detail-user" }, (result, response) => {
      if (result) {
        let detail = response["user_detail"];
        setUserName(detail["user_name"]);
        setEmail(detail["email"]);
        setBirth(detail["birthdate"]);
        setPhone(detail["user_phone"]);
        setEngname(detail["english_name"]);
        setLoginId(detail["login_id"]);
        setKoreanSpeaking(detail["korean_speaking"]);
        setIsUserLoaded(true);
      } else {
        alert("사용자 정보를 불러오지 못했습니다.");
      }
    });
  }, []);

  const checkPw = (password) => {
    if (password.length < 8) {
      setPasswordError("비밀번호는 8자 이상이어야 합니다.");
      setPasswordValid(false);
    } else {
      setPasswordError("");
      setPasswordValid(true);
    }
  };

  const digestMessage = async (message) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return await window.crypto.subtle.digest("SHA-256", data);
  };

  const hexString = (buffer) => {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      const paddedHexCode = hexCode.padStart(2, "0");
      return paddedHexCode;
    });

    return hexCodes.join("");
  };

  const handlePasswordChange = async () => {
    if (!isUserLoaded) {
      alert("사용자 정보를 불러오지 못했습니다.");
      return;
    }
    if (!passwordValid) {
      alert(
        passwordError ||
          "비밀번호가 유효하지 않습니다. The password is invalid."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("새 비밀번호와 확인용 비밀번호가 일치하지 않습니다.");
      setNewPassword("");
      setConfirmPassword("");
      return;
    }

    const digestValue = await digestMessage(`drenglish${newPassword}`); // Hashing;
    const hashed = hexString(digestValue);

    request(
      {
        "bk-working": "edit-user",
        need_password_update: true,
        password: hashed.toLowerCase(),
      },
      (result) => {
        alert(result ? "변경되었습니다" : "오류");
      }
    );

    setNewPassword("");
    setConfirmPassword("");
    setPasswordValid(false);
  };

  const removeSpaces = (str) => {
    return str.replace(/\s+/g, "");
  };

  const submit = () => {
    if (!isUserLoaded) {
      alert("사용자 정보를 불러오지 못했습니다.");
      return;
    }
    request(
      {
        "bk-working": "edit-user",
        user_name: userName,
        english_name: engname,
        korean_speaking: koreanSpeaking,
        email: email,
      },
      (result) => {
        alert(result ? "변경되었습니다." : "오류");
      }
    );
  };
  // const withdrawUser = () => {
  //   alert("회원탈퇴!");
  // };
  return (
    <>
      <form onSubmit={handleSubmit} className="user_info_form">
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <TextField
              label="이름"
              value={userName || ""}
              onChange={(e) => setUserName(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="수업용 영어이름"
              value={engname || ""}
              onChange={(e) => setEngname(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12} className="">
            {/* <TextField
              label="생년월일"
              value={birth || ""}
              onChange={(e) => setBirth(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
            /> */}
            {/* <InputLabel htmlFor="birth">생년월일</InputLabel>
            <Input id="birth" value={birth} readOnly /> */}
            <TextField
              label="생년월일"
              value={birth || ""}
              onChange={(e) => setEngname(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label="핸드폰"
              value={phone || ""}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12}>
            {/* <InputLabel htmlFor="loginId">아이디</InputLabel>
            <Input id="loginId" value={loginId} readOnly /> */}
            <TextField
              disabled
              label="아이디"
              value={loginId || ""}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label="이메일"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                한국어 사용 강사 선호
              </FormLabel>
              <RadioGroup
                className="flex-radio"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={koreanSpeaking}
                onChange={(e) => setKoreanSpeaking(e.target.value)}
              >
                <FormControlLabel value="Y" control={<Radio />} label="yes" />
                <FormControlLabel value="N" control={<Radio />} label="no" />
              </RadioGroup>
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <h2 className="new-password">비밀번호 변경</h2>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="새 비밀번호"
              type="password"
              value={newPassword || ""}
              onChange={(e) => {
                setNewPassword(e.target.value);
                checkPw(e.target.value);
              }}
              margin="normal"
              variant="outlined"
              error={!passwordValid && newPassword.length > 0}
              helperText={passwordError}
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="비밀번호 확인"
              type="password"
              value={confirmPassword || ""}
              onChange={(e) => setConfirmPassword(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Button onClick={handlePasswordChange} className="new-pw-btn">
              비밀번호 변경
            </Button>
          </Grid>
          <Grid item xs={12}>
            <strong>※ 비밀번호는 8자 이상이어야 합니다.</strong>
          </Grid>
        </Grid>
        <Button className="submit-btn" onClick={() => submit()}>
          수정
        </Button>
        {/* <Button className="withdraw-btn" onClick={() => withdrawUser()}>
          회원 탈퇴
        </Button> */}
      </form>
    </>
  );
};

export default UserInfo;
