import React, { useContext, useEffect, useMemo, useState } from "react";
import { Bar, BarChart, ResponsiveContainer } from "recharts";
import "../../../assets/main.css";
import "../../../assets/sub.css";
import "../../../assets/member.css";
import "../../../assets/tab.css";
import { Button, Modal } from "@mui/material";
// import { useEffect } from "react";
import { Box } from "@mui/system";
import MonthlyReport from "./report/MonthlyReport";
import { LoginContext } from "controller/provider/LoginProvider";
import {
  listingClassJoinCurrentCredit,
  listingClassReport,
} from "controller/ClassJoinController";
import { listingCredits } from "controller/CreditController";
import { useNavigate } from "react-router-dom";
import AttendanceReport from "./report/AttendanceReport";
// import { listingClass, listingClassJoin } from "controller/ClassJoinController";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "85vh",
  bgcolor: "#fff",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Course = () => {
  const navigate = useNavigate();
  const loginContext = useContext(LoginContext);
  const [reportopen, setReportOpen] = useState(false);
  const [classJoinArray, setClassJoinArray] = useState([]);
  const [myCreditArray, setMyCreditArray] = useState([]);
  //   const [classScheduleArray , setClassScheduleArray  ] = useState([])
  // const [classJoinArray, setClassJoinArray] = useState([])
  const [currentReport, setCurrentReport] = useState(null);
  const [personReport, setPersonReport] = useState(null);
  const [groupReport, setGroupReport] = useState(null);
  const [activeTab, setActiveTab] = useState("monthly");

  const openAppli = () => {
    navigate("/application");
  };
  const handleReportOpen = () => setReportOpen(true);
  const handleReportClose = () => setReportOpen(false);

  useEffect(() => {
    listingClassReport(new Date().getTime()).then(({ result, response }) => {
      if (result) {
        var mainReport;
        response["limit_array"].forEach((element) => {
          if (element["credit_type"] === "G") {
            setGroupReport(element);
            mainReport = element;
          } else if (element["credit_type"] === "P") {
            setPersonReport(element);
            if (!mainReport)
              mainReport = element;
          }
        });

        if (!currentReport) {
          setCurrentReport(mainReport);
        }
      }
    });

    listingClassJoinCurrentCredit(new Date().getTime(), 6).then(
      ({ result, response }) => {
        if (result) {
          setClassJoinArray(
            response["limit_array"].sort((a, b) =>
              (a.ymd * 10000 + parseInt(a.start_hhmm) <
              b.ymd * 10000 + parseInt(b.start_hhmm)
                ? 1
                : -1)
            )
          );
        }
      }
    );

    listingCredits(new Date().getTime(), false).then(({ result, response }) => {
      if (result) {
        setMyCreditArray(response["limit_array"]);
      } else {
        console.log("결제정보를 불러오지 못했습니다.");
      }
    });
  }, []);

  const data = useMemo(() => {
    const pass =
      currentReport?.reports && currentReport.reports.length > 0
        ? currentReport.reports.reduce((prev, item) => {
            if (item.substring(0, 1) === "P") {
              return prev + 1;
            }
            return prev;
          }, 0)
        : 0;

    const late =
      currentReport?.reports && currentReport.reports.length > 0
        ? currentReport.reports.reduce((prev, item) => {
            if (item.substring(0, 1) === "L") {
              return prev + 1;
            }
            return prev;
          }, 0)
        : 0;

    const absence =
      currentReport?.reports && currentReport.reports.length > 0
        ? currentReport.reports.reduce((prev, item) => {
            if (item.substring(0, 1) === "A") {
              return prev + 1;
            }
            return prev;
          }, 0)
        : 0;

    const cancel =
      currentReport?.reports && currentReport.reports.length > 0
        ? currentReport.reports.reduce((prev, item) => {
            if (item.substring(0, 1) === "C") {
              return prev + 1;
            }
            return prev;
          }, 0)
        : 0;

    const complete = pass + late;

    const reservation =
      currentReport?.reports && currentReport.reports.length > 0
        ? currentReport.reports.reduce((prev, item) => {
            if (item.substring(0, 1) === "R") {
              return prev + 1;
            }
            return prev;
          }, 0)
        : 0;

    const totalLimit =
      currentReport && currentReport.total_limit
        ? parseInt(currentReport.total_limit)
        : 0;
    const totalUsage =
      currentReport && currentReport.total_usage
        ? parseInt(currentReport.total_usage)
        : 0;
    const remaining =
      currentReport && totalLimit > 0 ? totalLimit - totalUsage : 0;

    return {
      data: [
        {
          name: "Page F",
          remaining:
            currentReport?.reports && currentReport.reports.length > 0
              ? (remaining / currentReport.reports.length) * 100
              : 100,
        }, //잔여횟수
        {
          name: "Page H",
          reservation:
            currentReport?.reports && currentReport.reports.length > 0
              ? (reservation / currentReport.reports.length) * 100
              : 0,
        }, //수강예약

        // {
        //   name: "Page A",
        //   attendance_rate:
        //     currentReport?.reports && currentReport.reports.length > 0
        //       ? (pass / currentReport.reports.length) * 100
        //       : 0,
        // },
        // {
        //   name: "Page B",
        //   attendance:
        //     currentReport?.reports && currentReport.reports.length > 0
        //       ? (pass / currentReport.reports.length) * 100
        //       : 0,
        // },
        // {
        //   name: "Page C",
        //   late:
        //     currentReport?.reports && currentReport.reports.length > 0
        //       ? (late / currentReport.reports.length) * 100
        //       : 0,
        // },
        {
          name: "Page G",
          complete:
            currentReport?.reports && currentReport.reports.length > 0
              ? (complete / currentReport.reports.length) * 100
              : 0,
        }, //수강완료(출석 + 지각)
        {
          name: "Page D",
          absence:
            currentReport?.reports && currentReport.reports.length > 0
              ? (absence / currentReport.reports.length) * 100
              : 0,
        }, //결석
        // {
        //   name: "Page E",
        //   cancellation:
        //     currentReport?.reports && currentReport.reports.length > 0
        //       ? (cancel / currentReport.reports.length) * 100
        //       : 0,
        // },
      ],
      pass,
      late,
      absence,
      cancel,
      complete,
      reservation,
      remaining,
    };
  }, [currentReport, myCreditArray]);

  const downloadTextbook = (level, urls) => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = level + ".zip";
    urls.forEach(async function (url) {
      const filename = url.split("?")[0].split("/").slice(-1).pop();
      try {
        const file = await JSZipUtils.getBinaryContent(url);
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        alert(filename + " 오류");
        console.log(err);
      }
    });
  };

  const openMypage = () => {
    navigate("/mypage");
  };

  const filteredArray = myCreditArray.filter(
    (item) => item["credit_type"] === "G" || item["credit_type"] === "P"
  );

  const groupedCredits = {};
  filteredArray.forEach((item) => {
    if (!groupedCredits[item["credit_type"]]) {
      groupedCredits[item["credit_type"]] = item;
    }
  });

  return (
    <div className="course">
      <div className="container">
        <div className="con-tit">
          <h1>
            Hello! {loginContext.user.userName}
            <span onClick={openMypage} className="edit_btn">
              &nbsp; ⚙
            </span>
          </h1>
          <p>내 수업일정을 확인해보세요</p>
        </div>
        <div className="course-wrap flex" style={{minHeight: '400px'}}>
          <div className="course-box">
            <div className="course-b-top flex">
              <h2>수강 예약 리스트</h2>
              <button onClick={openAppli}>+ 더보기</button>
            </div>
            <div className="course-date">
              <p>기간</p>
              <div className="course-date-wrap">
                {Object.values(groupedCredits).map((item, index) => (
                  <div className="course-total" key={index}>
                    {new Date(item["start_millisec"]).toLocaleDateString()} ~{" "}
                    {new Date(item["end_millisec"]).toLocaleDateString()}
                    <span>
                      {item["credit_type"] === "G" ? "(그룹)" : "(개인)"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <table className="course-table">
              <thead>
                <tr>
                  <th>no</th>
                  <th>수강일</th>
                  <th>수업시간/분</th>
                  <th>레벨</th>
                  <th>출결</th>
                  <th>교재</th>
                </tr>
              </thead>
              <tbody>
                {classJoinArray
                  .filter(
                    (item) =>
                      !item["class_report"] ||
                      (item["class_report"] &&
                        item["class_report"].charAt(0) !== "C")
                  )
                  .slice(0, 12)
                  .map((item, index) => {
                    const ymd = item["ymd"] + "";
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {ymd.substring(0, 4)}-{ymd.substring(4, 6)}-
                          {ymd.substring(6, 8)}
                        </td>
                        <td>
                          {item["start_hhmm"].substring(0, 2)}:
                          {item["start_hhmm"].substring(2, 4)} / 30분
                        </td>
                        <td>{item["level"]}</td>
                        <td className="attendance_table">
                          {!item["class_report"] ? (
                            <span className="reservation attendance_td">
                              예약
                            </span>
                          ) : (
                            <span
                              className={`${
                                item["class_report"].substr(0, 1) === "P"
                                  ? "presence"
                                  : item["class_report"].substr(0, 1) === "L"
                                  ? "late"
                                  : item["class_report"].substr(0, 1) === "A"
                                  ? "absence"
                                  : ""
                              } attendance_td`}
                            >
                              {item["class_report"].substr(0, 1) === "P"
                                ? "출석"
                                : item["class_report"].substr(0, 1) === "L"
                                ? "지각"
                                : item["class_report"].substr(0, 1) === "A"
                                ? "결석"
                                : ""}
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            className="book-down"
                            onClick={() =>
                              downloadTextbook(
                                item["level"],
                                item["book_files"]
                              )
                            }
                          >
                            다운로드
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="course-box course-box2">
            <div className="course-b-top flex">
              <h2>학습현황</h2>
              {groupReport != null && (
                <nav className="course-nav flex">
                  <button
                    onClick={() => setCurrentReport(groupReport)}
                    className={currentReport === groupReport ? "active" : ""}
                  >
                    그룹
                  </button>
                  {personReport && (
                    <button
                      onClick={() => setCurrentReport(personReport)}
                      className={currentReport === personReport ? "active" : ""}
                    >
                      개인
                    </button>
                  )}
                </nav>
              )}
              <button onClick={handleReportOpen}>+ 성적 출력</button>
            </div>
            <div className="course-tab">
              <div id="personal" className="course-content">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={150} height={40} data={data}>
                    <Bar dataKey="remaining" fill="#3243ab" barSize={100} />
                    <Bar dataKey="reservation" fill="#3243ab" barSize={100} />
                    <Bar dataKey="complete" fill="#afb2c4" barSize={100} />
                    <Bar dataKey="absence" fill="#afb2c4" barSize={100} />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div id="corporate" className="course-content">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={150} height={40} data={data}>
                    <Bar dataKey="remaining" fill="#3243ab" barSize={100} />
                    <Bar dataKey="reservation" fill="#3243ab" barSize={100} />
                    <Bar dataKey="complete" fill="#afb2c4" barSize={100} />
                    <Bar dataKey="absence" fill="#afb2c4" barSize={100} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {myCreditArray && myCreditArray.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={150} height={40} data={data.data}>
                  <Bar dataKey="remaining" fill="#3243ab" barSize={100} />
                  <Bar dataKey="reservation" fill="#3243ab" barSize={100} />
                  <Bar dataKey="complete" fill="#afb2c4" barSize={100} />
                  <Bar dataKey="absence" fill="#afb2c4" barSize={100} />
                </BarChart>
              </ResponsiveContainer>
            )}
            <div className="c-chart-tit flex">
              <p>
                잔여횟수(
                {currentReport
                  ? parseInt(currentReport.total_limit) -
                    parseInt(currentReport.total_usage)
                  : 0}
                )
              </p>
              <p>
                수강예약(
                {data.reservation})
              </p>
              <p>수강완료({data.complete})</p>
              <p>결석({data.absence})</p>
            </div>
          </div>

          <Modal
            className="rep-pop"
            open={reportopen}
            onClose={handleReportClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="report_box">
              <button className="close-btn" onClick={handleReportClose}>
                닫기
              </button>
              <div className="tab-container">
                <input
                  type="radio"
                  id="tab1"
                  name="tabs"
                  className="tab"
                  checked={activeTab === "monthly"}
                  onChange={() => setActiveTab("monthly")}
                />
                <input
                  type="radio"
                  id="tab2"
                  name="tabs"
                  className="tab"
                  checked={activeTab === "weekly"}
                  onChange={() => setActiveTab("weekly")}
                />

                <label
                  htmlFor="tab1"
                  className={`tab-label ${
                    activeTab === "monthly" ? "active" : ""
                  }`}
                >
                  월별 레포트
                </label>
                <label
                  htmlFor="tab2"
                  className={`tab-label ${
                    activeTab === "weekly" ? "active" : ""
                  }`}
                >
                  기간별 레포트
                </label>

                <div
                  className={`monthly-box ${
                    activeTab === "monthly" ? "active" : ""
                  }`}
                >
                  <div>
                    <MonthlyReport />
                  </div>
                </div>

                <div
                  className={`weekly-box ${
                    activeTab === "weekly" ? "active" : ""
                  }`}
                >
                  <div>
                    <AttendanceReport />
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Course;
