import { Divider, MenuItem, Select } from "@mui/material";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { listingProduct } from "controller/PaymentController";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginContext } from "controller/provider/LoginProvider";

import "../../../../assets/main.css";
import "../../../../assets/sub.css";
import { request } from "network/Request";

const Payment = () => {
  const loginContext = useContext(LoginContext);

  const selector = "#payment-widget";
  const clientKey = "live_ck_Wd46qopOB89p67ZQ56ErZmM75y0v";
  // const customerKey = useContext(loginContext.user.userExternalId);
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  // =========toss========
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    setCustomerName(loginContext.user?.userName || "");
  }, [loginContext.user]);

  useEffect(() => {
    if (!loginContext.user) {
      return;
    }
    (async () => {
      const paymentWidget = await loadPaymentWidget(
        clientKey,
        loginContext.user?.userExternalId
      );
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        ""
      );
      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, [loginContext]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(
      selectedProduct.price,
      paymentMethodsWidget.UPDATE_REASON.COUPON
    );
  }, [selectedProduct]);

  // ==토스위젯,페이먼츠 연동==
  useEffect(() => {
    const productId = 123;
    const number = 10;

    listingProduct(productId, number)
      .then(({ result, response }) => {
        if (result) {
          setProducts(response["product_array"]);
          console.log(response["product_array"]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleProductChange = (event) => {
    setSelectedProduct(
      products.filter((item) => item["product_id"] === event.target.value)[0]
    );
    setSelectedProductId(event.target.value);
  };

  return (
    <div className="container payment">
      <div className="con-tit align-center">
        <h1>수강료 결제</h1>
        <p>수강 횟수 선택 후 결제해주세요.</p>
      </div>
      <div className="pay-container">
        <div className="pay-top">
          <div className="pay-amount">
            <div className="pay-amo-wrap">
              <Select
                className="pay-cou"
                value={selectedProductId || ""}
                onChange={handleProductChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  수강 횟수를 선택해주세요.
                </MenuItem>
                {products.map((product, index) => [
                  <MenuItem key={product.product_id} value={product.product_id}>
                    {`${
                      product.product_name
                    } (${product.price.toLocaleString()}원)`}
                  </MenuItem>,
                  (index + 1) % 3 === 0 && <Divider key={`divider-${index}`} />,
                ])}
              </Select>
            </div>
          </div>
          <div className="payment">
            <span>최종 결제 금액</span>
            <span className="pay-bg-grey">{`${
              selectedProduct?.price.toLocaleString() ?? 0
            }원`}</span>
          </div>
        </div>

        <div id="payment-widget" />
        <button
          className="pay-btn"
          onClick={async () => {
            const paymentWidget = paymentWidgetRef.current;
            request(
              {
                "bk-working": "payment-start",
                product_id: selectedProduct.product_id,
              },
              async (result, response) => {
                if (result) {
                  try {
                    const result = await paymentWidget?.requestPayment({
                      orderId: response.order_id,
                      orderName: selectedProduct.product_name,
                      customerName: customerName,
                      successUrl: `${window.location.origin}/success`,
                      failUrl: `${window.location.origin}/fail`,
                    });
                    console.log(result); // 결제 결과에 대한 정보 출력
                  } catch (error) {
                    // handle error
                  }
                } else {
                  alert("오류");
                }
              }
            );
          }}
        >
          결제하기
        </button>
      </div>
    </div>
  );
};

export default Payment;
