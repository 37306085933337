// project import
import Routes from "./routes";
import ScrollTop from "./components/ScrollTop";
import { LoginProvider } from "controller/provider/LoginProvider";
import { CourseProvider } from "controller/provider/CourseProvider";
import { useEffect, useState } from "react";
import { requestOpen } from "network/Request";
import { Button, Modal } from "@mui/material";

const PopupModal = ({ item }) => {
  const [open, setOpen] = useState(true);

  const hidePopup = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    var json = JSON.parse(localStorage.getItem("hidden_popups") ?? "{}");
    console.log(date.getTime());
    json[[item['popup_id']]] = date.getTime();
    localStorage.setItem("hidden_popups", JSON.stringify(json));
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="main-pop"
    >
      <div className="main-pop-wrap">

          <div className="main-pop-img">
            <img src={item['image']} />
          </div>
          <div className="main-pop-btn">
            <Button onClick={() => hidePopup()}>7일간 보지 않기</Button>
            <Button onClick={() => setOpen(false)}>닫기</Button>
          </div>

      </div>
    </Modal>
  );
};

const App = () => {
  const [popupArray, setPopupArray] = useState([]);

  useEffect(() => {
    requestOpen({ "bk-working": "listing-popup" }, (result, response) => {
      if (result) {
        const time = new Date().getTime();
        const json = JSON.parse(localStorage.getItem("hidden_popups") ?? "{}");
        setPopupArray(
          response["limit_array"].filter((item) => {
            if (!json[[item["popup_id"]]] || json[[item["popup_id"]]] < time) {
              return true;
            }
            return false;
          })
        );
      }
    });
  }, []);

  return (
    <div>
    <ScrollTop>
      <LoginProvider>
        <CourseProvider>
          <Routes />
        </CourseProvider>
      </LoginProvider>
    </ScrollTop>
      {popupArray.map((item, index) => (
        <PopupModal key={index} item={item} />
      ))}
    </div>
  );
};

export default App;
