import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { requestOpen } from "network/Request";
const styles = {
  container: {
    marginTop: "16px",
  },
  title: {
    // fontSize: "24px",
    // fontWeight: "bold",
    // marginBottom: "8px",
  },
  content: {
    fontSize: "16px",
  },
  formControl: {
    minWidth: 200,
    marginTop: 16,
  },
  selectedTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: 16,
  },
  selectedContent: {
    fontSize: "16px",
    marginTop: 8,
  },
};

const rowsPerPage = 10;

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    requestOpen({
      "bk-working": "listing-faq",
      limit_offset: (page - 1) * rowsPerPage,
      limit_range: rowsPerPage,
    }, (result, response) => {
      if (result) {
        setRows(response['limit_array']);
        setTotalSize(response['limit_size']);
      } else {
        alert("데이터를 불러오지 못 했습니다.");
      }
    });
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className="commu-container">
        {/* <Typography variant="h4" component="h1" align="center">
          FAQ
        </Typography> */}

        <Box className="faq-wrap">
          {rows.map((faq) => (
            <Accordion
              key={faq.faq_id}
              expanded={expanded === `panel${faq.faq_id}`}
              onChange={handleAccordionChange(`panel${faq.faq_id}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${faq.faq_id}-content`}
                id={`panel${faq.faq_id}-header`}
              >
                <Typography variant="h6" component="h2" style={styles.title} className="faq-tit">
                  <span className="faq-wrap-tit-i">Q. </span> <span className="faq-wrap-tit">{faq.title}</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" style={styles.content} className="faq-wrap-con">
                  <span className="faq-wrap-tit-i">A. </span><span className="faq-wrap-con-txt">{faq.body}</span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <div className="pagination">
          <Pagination count={Math.ceil(totalSize / rowsPerPage)} page={page} onChange={(e, page) => setPage(page)} />
        </div>

      </div>
    </div>
  );
};

export default FAQ;
