export const ymd = (date) => {
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + month + day;
};

export const ymdToDate = (ymd) => {
  const ymdString = ymd + "";
  return new Date(
    ymdString.substring(0, 4),
    ymdString.substring(4, 6) - 1,
    ymdString.substring(6, 8)
  );
};

export const dateDiff = (date1, date2) => {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  const diffTime = Math.abs(date1 - date2);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
