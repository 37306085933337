import React, { useEffect } from "react";
import "../../../assets/main.css";
import "../../../assets/member.css";
import { useLocation } from "react-router-dom";

const CertificationResult = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    console.log(params.get('kmc_cert'), params.get('cert_num'));
    // window.opener.location.href = "http://localhost:3000/join?rec_cert=" + params.get('rec_cert') + "&" + "cert_num=" + params.get('cert_num');
    window.close();
    window.opener.postMessage({kmc_cert: params.get('kmc_cert'), cert_num: params.get('cert_num')});
  }, []);

  return (
    <div>
      <div className="container">
        {params.toString()}
      </div>
    </div>
  );
};

export default CertificationResult;
