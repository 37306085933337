import { request } from "network/Request";

export const listingCompanyHolidays = (startMin, endMin, ym00, koreanSpeaking) => {
  return request({
    "bk-working": "listing-un-reservable-days",
    start_time: startMin,
    end_time: endMin,
    ym00,
    korean_speaking: koreanSpeaking
  });
};

export const listingNationalHolidays = (startYmd, endYmd) => {
  return request({
    "bk-working": "listing-national-holidays",
    start_ymd: startYmd,
    end_ymd: endYmd,
  });
};

export const listingReservableHours = (ymd, koreanSpeaking) => {
  return request({
    "bk-working": "listing-reservable-hours",
    korean_speaking: koreanSpeaking,
    ymd: ymd,
  });
};

export const listingClassSchedule = () => {
  return request({
    "bk-working": "listing-class-schedule",
  });
};

export const listingClass = () => {
  return request({
    "bk-working": "listing-class",
  });
};

export const insertClassJoin = (classId, ymd, classScheduleId, creditId, koreanSpeaking) => {
  return request({
    "bk-working": "insert-class-join",
    class_id: classId,
    ymd: ymd,
    class_schedule_id: classScheduleId,
    credit_id: creditId,
    korean_speaking: koreanSpeaking
  });
};

export const editClassJoin = (classJoinId, ymd, classId, classScheduleId) => {
  return request({
    "bk-working": "edit-class-join",
    ymd: ymd,
    class_join_id: classJoinId,
    class_id: classId,
    class_schedule_id: classScheduleId,
  });
};

export const deleteClassJoin = (classJoinId, creditId) => {
  return request({
    "bk-working": "delete-class-join",
    class_join_id: classJoinId,
    credit_id: creditId,
  });
};

export const listingClassJoinCurrentCredit = (timeMillisec, range = 6) => {
  return request({
    "bk-working": "listing-my-class-join-credit",
    current_millisec: timeMillisec,
    limit_range: range,
  });
};

export const listingClassJoinFilter = (startYmd, endYmd) => {
  return request({
    "bk-working": "listing-my-class-join-filter",
    start_ymd: startYmd,
    end_ymd: endYmd,
  });
};

export const listingClassReport = (currentMillisec) => {
  return request({
    "bk-working": "listing-my-class-report",
    current_millisec: currentMillisec,
  });
};
