import { request } from "network/Request";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Success = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    request(
      {
        "bk-working": "payment-toss",
        total_price: searchParams.get("amount"),
        order_id: searchParams.get("orderId"),
        payment_key: searchParams.get("paymentKey"),
      },
      (result, response) => {
        if (!result) {
          console.log(response);
        }
      }
    );
  }, []);

  return (
    <div className="container">
      <h1>결제 성공</h1>
      <div>{`주문 아이디: ${searchParams.get("orderId")}`}</div>
      <div>{`결제 금액: ${Number(
        searchParams.get("amount")
      ).toLocaleString()}원`}</div>
    </div>
  );
};

export default Success;
