
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginLayout from "../layout/ClassLayout/LoginLayout";
import Home from "../pages/Class/Common/Home";
import Join from "../pages/Class/Login/Join/Join";
import Login from "../pages/Class/Login/Login";
import TeacherInfo from "../pages/Class/Common/TeacherInfo";
import ClassInfo from "../pages/Class/Common/ClassInfo";
import FindId from "pages/Class/Login/FindId";
import FindPassword from "pages/Class/Login/FindPassword";
import Community from "pages/Class/Common/community/Community";
import CertificationResult from "pages/Class/Login/CertificationResult";

const LoginRoutes = () => {
  console.log("route");
  return (
    <Routes>
      <Route path="/" element={<LoginLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/teacher-info" element={<TeacherInfo />} />
        <Route path="/class-info" element={<ClassInfo />} />
        <Route path="/login" element={<Login />} />
        <Route path="/find-id" element={<FindId />} />
        <Route path="/find-password" element={<FindPassword />} />
        <Route path="/join" element={<Join />} />
        <Route path="/certification-result" element={<CertificationResult />} />
        <Route path="/community/*" element={<Community />} />
        <Route path="/certificate/*" element={<Community />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Route>
    </Routes>
  );
};
export default LoginRoutes;
